import { countries } from 'countries-list';

import { Check, ChevronsUpDown } from 'lucide-react';
import { useState } from 'react';

import TooltipWrapper from '@/components/ui/TooltipWrapper';
import { Button } from '@/components/ui/button';
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
} from '@/components/ui/command';
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from '@/components/ui/popover';
import { cn } from '@/lib/utils';

const COUNTRIES_ARRAY = Object.values(countries).map(({ name }) => name);

const CountryCombobox = ({
    value,
    onChange,
    disabled,
}: {
    value: string;
    onChange: (value: string) => void;
    disabled?: boolean;
}) => {
    const [open, setOpen] = useState(false);

    const buttonText = value
        ? COUNTRIES_ARRAY.find((country) => country === value)
        : 'Select a country';

    return (
        <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger asChild>
                <Button
                    disabled={disabled}
                    variant='outline'
                    role='combobox'
                    aria-expanded={open}
                    className={cn(
                        'border-divider w-full justify-between text-sm font-normal text-newDesign-text-secondary',
                        {
                            'text-newDesign-text-primary':
                                value && value?.length > 1,
                        },
                    )}>
                    <TooltipWrapper text={buttonText}>
                        <p className='overflow-hidden text-ellipsis whitespace-nowrap'>
                            {buttonText}
                        </p>
                    </TooltipWrapper>
                    <ChevronsUpDown className='size-5 opacity-50' />
                </Button>
            </PopoverTrigger>

            {/* https://github.com/shadcn-ui/ui/issues/1690#issuecomment-2016635419 */}
            <PopoverContent className='max-h-[--radix-popover-content-available-height] min-w-fit p-0'>
                <Command value={value}>
                    <CommandInput placeholder='Search countries' />
                    <CommandList>
                        <CommandEmpty>No country found.</CommandEmpty>
                        <CommandGroup>
                            {COUNTRIES_ARRAY.map((country) => (
                                <CommandItem
                                    key={country}
                                    value={country}
                                    onSelect={(currentValue) => {
                                        onChange(currentValue);
                                        setOpen(false);
                                    }}>
                                    {country}
                                    <Check
                                        className={cn(
                                            'ml-auto',
                                            value === country
                                                ? 'opacity-100'
                                                : 'opacity-0',
                                        )}
                                    />
                                </CommandItem>
                            ))}
                        </CommandGroup>
                    </CommandList>
                </Command>
            </PopoverContent>
        </Popover>
    );
};

export default CountryCombobox;
