import React from 'react';

import { getAppTexts } from '@/app-config/appSpecializationTexts.ts';
import CreateWhatIfCard from '@/app/digital-twin/pages/Overview/CreateWhatIfCard/CreateWhatIfCard.tsx';
import { useListMistModels } from '@/features/digital-twin/MistSettings/api/list-mist-models.ts';

import ScenariosCreatedCard from './ScenariosCreatedCard/ScenariosCreatedCard.tsx';

const Overview = () => {
    useListMistModels();

    return (
        <div className='flex w-full flex-1 flex-col gap-4'>
            <h1 className='text-2xl font-bold text-newDesign-black'>
                {getAppTexts().overviewPage.pageTitle}
            </h1>
            <div className='h-full w-full flex-col pb-2'>
                <div className='flex rounded-lg border border-newDesign-divider bg-newDesign-background p-4'>
                    {/* TODO: show card conditionally base on whether the API is connected or not */}
                    {/*<LatestUpdateCard />*/}
                    <CreateWhatIfCard />
                </div>
                <div className='mt-2 flex rounded-lg border border-newDesign-divider bg-newDesign-background p-4'>
                    <ScenariosCreatedCard />
                </div>
            </div>
        </div>
    );
};

export default Overview;
