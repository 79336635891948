import { multiply, divide } from '@bae/utils';

import {
    AccessPointResponse,
    AccessPointInput,
    FteResponse,
    FteInput,
    MistConfigurationResponse,
    MistConfigurationInput,
    MistDataResponse,
    MistDataInput,
    WANEdgeResponse,
    WANEdgeInput,
    SiteInput,
    SiteResponse,
} from '../types.ts';

const accessPointResponseAdapter = (
    response: AccessPointResponse[],
): AccessPointInput[] => {
    return response.map((ap) => ({
        ...ap,
        trafficMbps: ap.trafficMbps.toString(),
        clientsPerSite: ap.clientsPerSite.toString(),
        avgTrafficPerAP: ap.avgTrafficPerAP.toString(),
        avgClientsPerAP: ap.avgClientsPerAP.toString(),
        trafficGrowth: multiply(ap.trafficGrowth, 100).toString(),
        clientGrowth: multiply(ap.clientGrowth, 100).toString(),
    }));
};

const accessPointInputAdapter = (
    input: AccessPointInput[],
): AccessPointResponse[] => {
    return input.map((ap) => ({
        ...ap,
        group: ap.group ?? [],
        trafficMbps: Number(ap.trafficMbps),
        clientsPerSite: Number(ap.clientsPerSite),
        avgTrafficPerAP: Number(ap.avgTrafficPerAP),
        avgClientsPerAP: Number(ap.avgClientsPerAP),
        trafficGrowth: divide(Number(ap.trafficGrowth), 100),
        clientGrowth: divide(Number(ap.clientGrowth), 100),
    }));
};

const siteInputAdapter = (input: SiteInput[]): SiteResponse[] => {
    return input.map((site) => ({
        ...site,
        group: site.group ?? [],
    }));
};

const siteResponseAdapter = (input: SiteResponse[]): SiteInput[] => {
    return input.map((site) => ({
        ...site,
        group: site.group ?? [],
    }));
};

const wanEdgeResponseAdapter = (
    response: WANEdgeResponse[],
): WANEdgeInput[] => {
    return response.map((wan) => ({
        ...wan,
        licenseBW: wan.licenseBW.toString(),
        avgTraffic: wan.avgTraffic.toString(),
        trafficGrowth: multiply(wan.trafficGrowth, 100).toString(),
    }));
};

const fteResponseAdapter = (response: FteResponse[]): FteInput[] => {
    return response.map((fte) => ({
        ...fte,
        annualExpense: fte.annualExpense.toString(),
        annualExpenseCagr: multiply(fte.annualExpenseCagr, 100).toString(),
        quantity: fte.quantity.toString(),
    }));
};

const fteInputAdapter = (input: FteInput[]): FteResponse[] => {
    return input.map((fte) => ({
        ...fte,
        annualExpense: Number(fte.annualExpense),
        annualExpenseCagr: divide(Number(fte.annualExpenseCagr), 100),
        quantity: Number(fte.quantity),
    }));
};

const configurationResponseAdapter = (
    config: MistConfigurationResponse,
): MistConfigurationInput => {
    return {
        ...config,
        capital_cost: multiply(config.capital_cost, 100).toString(),
        periods: config.periods.toString(),
        depreciation_time: config.depreciation_time.toString(),
    };
};

const configurationInputAdapter = (
    config: MistConfigurationInput,
): MistConfigurationResponse => {
    return {
        ...config,
        capital_cost: divide(Number(config.capital_cost), 100),
        periods: Number(config.periods),
        depreciation_time: Number(config.depreciation_time),
    };
};

const mistDataResponseAdapter = (response: MistDataResponse): MistDataInput => {
    return {
        ...response,
        sites: siteResponseAdapter(response.sites),
        accessPoints: accessPointResponseAdapter(response.accessPoints),
        wanEdge: wanEdgeResponseAdapter(response.wanEdge),
        ftes: fteResponseAdapter(response.ftes),
        configuration: configurationResponseAdapter(response.configuration),
    };
};

const mistDataInputAdapter = (input: MistDataInput): MistDataResponse => {
    const { sites, accessPoints, ftes, configuration, ...rest } = input;

    return {
        ...rest,
        sites: siteInputAdapter(sites),
        accessPoints: accessPointInputAdapter(accessPoints),
        wanEdge: [],
        ftes: fteInputAdapter(ftes),
        configuration: configurationInputAdapter(configuration),
    };
};

export {
    siteInputAdapter,
    siteResponseAdapter,
    accessPointResponseAdapter,
    accessPointInputAdapter,
    fteResponseAdapter,
    fteInputAdapter,
    configurationResponseAdapter,
    configurationInputAdapter,
    mistDataResponseAdapter,
    mistDataInputAdapter,
};
