import { isNull } from 'lodash';

import {
    ColumnDef,
    flexRender,
    getCoreRowModel,
    RowData,
    useReactTable,
} from '@tanstack/react-table';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { IoCheckmarkCircleOutline } from 'react-icons/io5';
import { LuPlus } from 'react-icons/lu';
import { MdEdit } from 'react-icons/md';
import { RiDeleteBin2Line } from 'react-icons/ri';

import { Button } from '@/components/ui/button.tsx';
import {
    Table,
    TableBody,
    TableHead,
    TableHeader,
    TableRow,
    TableCell,
    TableFooter,
} from '@/components/ui/table.tsx';
import { cn } from '@/lib/utils.ts';

import type { IQuantityTimeSeriesEntry } from '../../../types.ts';
import NumberField from './NumberField.tsx';

declare module '@tanstack/react-table' {
    interface TableMeta<TData extends RowData> {
        delayedDeployments?: {
            editRow?: number | null;
            editData?: IQuantityTimeSeriesEntry | null;
            singleDeployment?: boolean;
            isAddingNew?: boolean;
            handleStartEdit?: (row: IQuantityTimeSeriesEntry) => void;
            handleCancelEdit?: () => void;
            handleSaveEdit?: () => void;
            handleDelete?: (rowDelay: number) => void;
            setEditData?: (data: IQuantityTimeSeriesEntry) => void;
        };
    }
    interface ColumnMeta<TData extends RowData, TValue> {
        sticky?: string;
    }
}

// Column definitions moved outside the component
const columns: ColumnDef<IQuantityTimeSeriesEntry>[] = [
    {
        id: 'delay',
        accessorKey: 'delay',
        header: () => (
            <p className='text-xs text-newDesign-text-secondary'>
                Elapsed Time (Months)
            </p>
        ),
        cell: (cellCtx) => {
            const meta = cellCtx.table.options.meta?.delayedDeployments;
            const rowVal = cellCtx.row.original;
            const isEditing = meta?.editRow === rowVal.delay;

            if (isEditing && meta?.editData) {
                return (
                    <NumberField
                        value={meta.editData.delay}
                        onValueChange={(val) =>
                            meta.setEditData({
                                ...meta.editData,
                                delay: parseInt(val || '0'),
                            })
                        }
                    />
                );
            }
            return <p>{rowVal.delay}</p>;
        },
    },
    {
        id: 'quantity',
        accessorKey: 'quantity',
        header: () => (
            <p className='text-xs text-newDesign-text-secondary'>Quantity</p>
        ),
        cell: (cellCtx) => {
            const meta = cellCtx.table.options.meta?.delayedDeployments;
            const rowVal = cellCtx.row.original;
            const isEditing = meta?.editRow === rowVal.delay;

            if (isEditing && meta?.editData) {
                return (
                    <NumberField
                        value={meta.editData.quantity}
                        onValueChange={(val) =>
                            meta.setEditData({
                                ...meta.editData,
                                quantity: parseInt(val || '0'),
                            })
                        }
                    />
                );
            }
            return <p>{rowVal.quantity}</p>;
        },
    },
    {
        id: 'actions',
        accessorKey: 'actions',
        header: () => (
            <p className='text-center text-xs text-newDesign-text-secondary'>
                Actions
            </p>
        ),
        cell: (cellCtx) => {
            const meta = cellCtx.table.options.meta?.delayedDeployments;
            const rowVal = cellCtx.row.original;
            const isEditing = meta?.editRow === rowVal.delay;
            const singleDeployment = meta?.singleDeployment;
            const isAnotherRowEditing =
                (meta?.editRow !== null && !isEditing) || meta?.isAddingNew;

            if (isEditing) {
                return (
                    <div className='mr-1 flex items-center justify-center'>
                        <Button
                            onClick={meta?.handleCancelEdit}
                            size='icon'
                            variant='text'
                            className='group size-7 bg-transparent p-1 disabled:cursor-default disabled:bg-opacity-10'>
                            <LuPlus
                                className={cn(
                                    'size-4 rotate-45 group-hover:text-white',
                                )}
                            />
                        </Button>
                        <Button
                            onClick={meta?.handleSaveEdit}
                            size='icon'
                            variant='text'
                            className='group ml-1 size-7 bg-transparent p-1 hover:bg-newDesign-success-dark active:bg-newDesign-success disabled:cursor-default disabled:bg-opacity-10'>
                            <IoCheckmarkCircleOutline
                                className={cn(
                                    'size-4 text-newDesign-success-dark group-hover:text-white',
                                )}
                            />
                        </Button>
                    </div>
                );
            }

            return (
                !isAnotherRowEditing && (
                    <div className='mr-4 flex items-center justify-center'>
                        <Button
                            disabled={meta?.isAddingNew}
                            onClick={() => meta?.handleStartEdit(rowVal)}
                            size='icon'
                            variant='text'
                            className='group size-7 bg-transparent p-1 disabled:cursor-default disabled:bg-opacity-10'>
                            <MdEdit
                                className={cn('size-4 group-hover:text-white', {
                                    'opacity-0': meta?.isAddingNew,
                                })}
                            />
                        </Button>
                        {!singleDeployment && (
                            <Button
                                onClick={() => meta?.handleDelete(rowVal.delay)}
                                size='icon'
                                variant='text'
                                className='group ml-1 size-7 bg-transparent p-1 hover:bg-newDesign-error-light active:bg-newDesign-error disabled:cursor-default disabled:bg-opacity-10'>
                                <RiDeleteBin2Line
                                    className={cn(
                                        'size-4 text-newDesign-error group-hover:text-white',
                                    )}
                                />
                            </Button>
                        )}
                    </div>
                )
            );
        },
    },
];

interface DelayedDeploymentTableProps {
    data: IQuantityTimeSeriesEntry[];
    onChange: (entries: IQuantityTimeSeriesEntry[]) => void;
    title?: string;
    className?: string;
}

const DelayedDeploymentTable: FC<DelayedDeploymentTableProps> = ({
    data,
    onChange,
    title,
    className,
}) => {
    const [editRow, setEditRow] = useState<number | null>(null);
    const [editData, setEditData] = useState<IQuantityTimeSeriesEntry | null>(
        null,
    );
    const [isAddingNew, setIsAddingNew] = useState(false);
    const [newRowData, setNewRowData] = useState<IQuantityTimeSeriesEntry>({
        delay: 0,
        quantity: 0,
    });

    const sortedData = useMemo(
        () => data.slice().sort((a, b) => a.delay - b.delay),
        [data],
    );

    const duplicateDeployment = !!data.find(
        (row) => row.delay === newRowData.delay,
    );

    const handleStartEdit = useCallback((row: IQuantityTimeSeriesEntry) => {
        setEditRow(row.delay);
        setEditData({ ...row });
    }, []);

    const handleCancelEdit = useCallback(() => {
        setEditRow(null);
        setEditData(null);
    }, []);

    const handleSaveEdit = useCallback(() => {
        if (!editData) return;
        onChange(data.map((d) => (d.delay === editRow ? editData : d)));
        handleCancelEdit();
    }, [data, editData, editRow, onChange, handleCancelEdit]);

    const handleDelete = useCallback(
        (rowDelay: number) => {
            onChange(data.filter((d) => d.delay !== rowDelay));
        },
        [data, onChange],
    );

    const handleStartAdd = useCallback(() => {
        setIsAddingNew((prev) => !prev);
        setNewRowData({ delay: 0, quantity: 0 });
    }, []);

    const handleConfirmAdd = useCallback(() => {
        if (duplicateDeployment) return;
        onChange([...data, newRowData]);
        setNewRowData({ delay: 0, quantity: 0 });
        setIsAddingNew(false);
    }, [data, duplicateDeployment, newRowData, onChange]);

    const table = useReactTable({
        data: sortedData,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getRowId: (row) => row.delay.toString(),
        meta: {
            delayedDeployments: {
                editRow,
                editData,
                singleDeployment: data.length === 1,
                isAddingNew,
                handleStartEdit,
                handleCancelEdit,
                handleSaveEdit,
                handleDelete,
                setEditData,
            },
        },
    });

    return (
        <div className={className}>
            <div
                className={cn('flex items-center justify-end', {
                    'justify-between': !!title,
                })}>
                {title && (
                    <p className='leading-1.5 text-sm font-medium'>{title}</p>
                )}

                <Button
                    disabled={!isNull(editRow)}
                    onClick={handleStartAdd}
                    size='sm'
                    withIcon
                    variant='text'>
                    <LuPlus
                        className={cn('mr-1 size-4 transition-all', {
                            'rotate-45': isAddingNew,
                        })}
                    />
                    {isAddingNew ? 'Cancel' : 'Add new'}
                </Button>
            </div>

            <Table maxHeight='max-h-[350px]'>
                <TableHeader>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <TableRow key={headerGroup.id}>
                            {headerGroup.headers.map((header) => {
                                const accessorKey =
                                    header.column.columnDef['accessorKey'];

                                return (
                                    <TableHead
                                        className={
                                            ['quantity', 'actions'].includes(
                                                accessorKey,
                                            )
                                                ? 'min-w-[120px]'
                                                : 'w-full'
                                        }
                                        key={header.id}>
                                        {header.isPlaceholder
                                            ? null
                                            : flexRender(
                                                  header.column.columnDef
                                                      .header,
                                                  header.getContext(),
                                              )}
                                    </TableHead>
                                );
                            })}
                        </TableRow>
                    ))}
                </TableHeader>

                <TableBody>
                    {/* Row for adding a new item */}
                    {isAddingNew && (
                        <TableRow>
                            <TableCell className='pl-1'>
                                <NumberField
                                    className={cn({
                                        'border-newDesign-error-dark':
                                            duplicateDeployment,
                                    })}
                                    value={newRowData.delay}
                                    onValueChange={(val) =>
                                        setNewRowData({
                                            ...newRowData,
                                            delay: parseInt(val || '0'),
                                        })
                                    }
                                />
                            </TableCell>
                            <TableCell className='pl-1'>
                                <NumberField
                                    value={newRowData.quantity}
                                    onValueChange={(val) =>
                                        setNewRowData({
                                            ...newRowData,
                                            quantity: parseInt(val || '0'),
                                        })
                                    }
                                />
                            </TableCell>
                            <TableCell className='mr-4 flex items-center justify-center'>
                                <Button
                                    size='icon'
                                    variant='text'
                                    className={cn(
                                        'group size-7 bg-transparent p-1',
                                        ' hover:bg-newDesign-success-dark active:bg-newDesign-success',
                                        'disabled:cursor-default disabled:bg-opacity-10',
                                    )}
                                    onClick={handleConfirmAdd}
                                    disabled={duplicateDeployment}>
                                    <IoCheckmarkCircleOutline
                                        className={cn(
                                            'size-4 text-newDesign-success group-hover:text-white',
                                            {
                                                'text-newDesign-text-secondary':
                                                    duplicateDeployment,
                                            },
                                        )}
                                    />
                                </Button>
                            </TableCell>
                        </TableRow>
                    )}

                    {/* Existing Rows */}
                    {table.getRowModel().rows.map((row) => (
                        <TableRow
                            key={row.id}
                            data-state={row.getIsSelected() && 'selected'}>
                            {row.getVisibleCells().map((cell) => {
                                const isEditingRow =
                                    editRow === row.original.delay;
                                return (
                                    <TableCell
                                        key={cell.id}
                                        className={cn({
                                            'pl-1': isEditingRow,
                                        })}>
                                        {flexRender(
                                            cell.column.columnDef.cell,
                                            cell.getContext(),
                                        )}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    ))}
                </TableBody>

                {isAddingNew && duplicateDeployment && (
                    <TableFooter>
                        <TableRow>
                            <TableHead colSpan={3}>
                                <p className='text-xs text-newDesign-error-dark'>
                                    Duplicate deployments are not allowed.
                                </p>
                            </TableHead>
                        </TableRow>
                    </TableFooter>
                )}
            </Table>
        </div>
    );
};

export default DelayedDeploymentTable;
