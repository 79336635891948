import { z } from 'zod';

import React, { useEffect, FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { Button } from '@/components/ui/button.tsx';
import {
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog.tsx';
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '@/components/ui/form.tsx';
import { Input } from '@/components/ui/input.tsx';
import CountryCombobox from '@/features/digital-twin/MistSettings/components/Sites/components/CountryCombobox.tsx';
import DelayedDeploymentTable from '@/features/digital-twin/MistSettings/components/shared/DelayedDeployment/DelayedDeploymentTable.tsx';
import useMistStore, {
    getIsSiteWithDevice,
} from '@/features/digital-twin/MistSettings/store';

import type { SiteInput } from '../../../types.ts';
import TimeZoneCombobox from './TimeZoneCombobox.tsx';
import { zodResolver } from '@hookform/resolvers/zod';

const formSchema = z.object({
    site: z
        .string()
        .min(2, 'Name must contain at least 2 characters')
        .max(50, 'Name must be at most 50 characters long'),
    country: z.string(),
    address: z.string(),
    timeZone: z.string(),
});

interface Props {
    isOpen: boolean;
    closeModal: () => void;
    site?: SiteInput;
}

const defaultValues = {
    site: '',
    country: '',
    address: '',
    timeZone: '',
};

const defaultDelayedDeployments = [
    {
        delay: 0,
        quantity: 1,
    },
];

const SiteDialog: FC<Props> = ({ isOpen, closeModal, site }) => {
    const addSite = useMistStore((state) => state.addSite);
    const updateSite = useMistStore((state) => state.updateSite);

    const sites = useMistStore((state) => state.data.sites);

    const [delayedDeployments, setDelayedDeployments] = useState(
        defaultDelayedDeployments,
    );

    useEffect(() => {
        if (site?.qtyTimeSeries) {
            setDelayedDeployments(site.qtyTimeSeries);
        }
    }, [site, isOpen]);

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: site
            ? {
                  site: site.site,
                  country: site.country,
                  address: site.address,
                  timeZone: site.timeZone,
              }
            : defaultValues,
    });

    function onSubmit(values: z.infer<typeof formSchema>) {
        if (site) {
            const isSiteWithDevice = getIsSiteWithDevice(site.site);

            if (site.site !== values.site && isSiteWithDevice) {
                toast.error('This site has devices making reference to it.');
                return;
            }

            const isDuplicate = sites.find(
                (siteItem) =>
                    siteItem.site.toLowerCase() === values.site.toLowerCase() &&
                    values.site.toLowerCase() !== site.site.toLowerCase(),
            );

            if (isDuplicate) {
                toast.error('Duplicate sites are not allowed.');
                return;
            }

            updateSite(site, {
                ...values,
                group: [],
                qtyTimeSeries: delayedDeployments,
                status: site.status === 'original' ? 'modified' : site.status,
            } as SiteInput);
        } else {
            const isDuplicate = sites.find(
                ({ site }) => site.toLowerCase() === values.site.toLowerCase(),
            );

            if (isDuplicate) {
                toast.error('Duplicate sites are not allowed.');
                return;
            }

            addSite({
                ...values,
                group: [],
                qtyTimeSeries: delayedDeployments,
                status: 'added',
            } as SiteInput);
        }

        closeModal();
    }

    useEffect(() => {
        if (!isOpen) {
            form.reset();
            setDelayedDeployments(
                site ? site.qtyTimeSeries : defaultDelayedDeployments,
            );
        }
    }, [site, form, isOpen]);

    return (
        <DialogContent className='max-h-dvh max-w-[500px] overflow-y-auto sm:max-w-[1000px]'>
            <DialogHeader>
                <DialogTitle>New Site</DialogTitle>
                <DialogDescription>
                    Fill in the fields below to add a new Site.
                </DialogDescription>
            </DialogHeader>

            <div className='grid grid-cols-1 gap-8 lg:grid-cols-2'>
                <Form {...form}>
                    <form
                        onSubmit={form.handleSubmit(onSubmit)}
                        className='grid grid-cols-1 gap-4 sm:grid-cols-2'>
                        <FormField
                            control={form.control}
                            name='site'
                            render={({ field }) => (
                                <FormItem className='col-span-2'>
                                    <FormLabel>Site</FormLabel>
                                    <FormControl>
                                        <Input
                                            placeholder='Enter the Site name...'
                                            {...field}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name='country'
                            render={({
                                field: { onChange, value, disabled },
                            }) => {
                                return (
                                    <FormItem>
                                        <FormLabel>
                                            Country (optional)
                                        </FormLabel>
                                        <FormControl>
                                            <CountryCombobox
                                                value={value}
                                                onChange={onChange}
                                                disabled={disabled}
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                );
                            }}
                        />

                        <FormField
                            control={form.control}
                            name='address'
                            render={({ field }) => {
                                return (
                                    <FormItem>
                                        <FormLabel>
                                            Address (optional)
                                        </FormLabel>
                                        <FormControl>
                                            <Input
                                                placeholder='Enter the address...'
                                                {...field}
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                );
                            }}
                        />

                        <FormField
                            control={form.control}
                            name='timeZone'
                            render={({
                                field: { onChange, value, disabled },
                            }) => {
                                return (
                                    <FormItem>
                                        <FormLabel>
                                            Time Zone (optional)
                                        </FormLabel>
                                        <FormControl>
                                            <TimeZoneCombobox
                                                value={value}
                                                onChange={onChange}
                                                disabled={disabled}
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                );
                            }}
                        />
                    </form>
                </Form>
                <DelayedDeploymentTable
                    title='Delayed Deployment'
                    data={delayedDeployments}
                    onChange={setDelayedDeployments}
                    className='w-full'
                />
            </div>
            <DialogFooter className='gap-4 lg:gap-0'>
                <Button onClick={closeModal} type='button' variant='outline'>
                    Cancel
                </Button>
                <Button onClick={form.handleSubmit(onSubmit)} type='submit'>
                    Add
                </Button>
            </DialogFooter>
        </DialogContent>
    );
};

export default SiteDialog;
