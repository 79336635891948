import React, { useEffect, useState } from 'react';
import { LuPencil } from 'react-icons/lu';

import { Button } from '@/components/ui/button.tsx';
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog.tsx';
import { Input } from '@/components/ui/input.tsx';
import { Label } from '@/components/ui/label.tsx';
import { Separator } from '@/components/ui/separator.tsx';

const CreateScenarioDialog = ({
    isModalOpen,
    closeModal,
    handleCreate,
}: {
    isModalOpen: boolean;
    closeModal: () => void;
    handleCreate: (name: string) => void;
}) => {
    const [scenarioName, setScenarioName] = useState('');

    const onCreateClick = () => {
        handleCreate(scenarioName);
        closeModal();
    };

    useEffect(() => {
        setScenarioName('');
    }, [isModalOpen]);

    return (
        <Dialog open={isModalOpen} onOpenChange={closeModal}>
            <DialogContent className='max-w-sm'>
                <DialogHeader>
                    <DialogTitle className='flex items-center'>
                        <LuPencil className='mr-2 size-5 text-newDesign-primary' />
                        Create scenario
                    </DialogTitle>
                    <DialogDescription className='text-newDesign-text-secondary'>
                        Type in the name to create a new scenario.
                    </DialogDescription>
                </DialogHeader>

                <div>
                    <Label htmlFor='scenarioName' className='mb-2'>
                        Scenario Name
                    </Label>
                    <Input
                        id='scenarioName'
                        name='scenarioName'
                        placeholder='Enter the scenario name...'
                        value={scenarioName}
                        onChange={(event) =>
                            setScenarioName(event.target.value)
                        }
                        className='h-10 w-full'
                    />
                </div>

                <Separator orientation='horizontal' />

                <DialogFooter className='items-center'>
                    <Button
                        onClick={closeModal}
                        variant='text'
                        className='text-newDesign-text-secondary'>
                        Cancel
                    </Button>
                    <Button onClick={onCreateClick}>Create</Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};

export default CreateScenarioDialog;
