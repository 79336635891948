import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { RouteMap } from '@bae/routes';

import { getAppTexts } from '@/app-config/appSpecializationTexts.ts';
import LatestUpdateIllustration from '@/assets/latest-update.svg';
import { Button } from '@/components/ui/button.tsx';
import { usePostMistModel } from '@/features/digital-twin/MistSettings/api/post-mist-model.ts';
import CreateScenarioDialog from '@/features/digital-twin/MistSettings/components/dialogs/CreateScenarioDialog.tsx';

const CreateWhatIfCard = () => {
    const history = useHistory();

    const [openCreateDialog, setOpenCreateDialog] = useState(false);

    const navigateToDashboard = (id: string) => {
        history.push(`${RouteMap.scenarios.path}/${id}/settings`);
    };

    const createScenarioQuery = usePostMistModel({
        mutationConfig: {
            onSuccess: (data) => {
                toast.success('Scenario created successfully', {
                    autoClose: 2000,
                });
                navigateToDashboard(data.model_id);
            },
            onError: () => {
                toast.error('Failed to create scenario');
            },
        },
    });

    const confirmCreateScenario = (name: string) => {
        createScenarioQuery.mutate({ group_name: name });
    };

    return (
        <div className='grid h-full w-full grid-cols-2 grid-rows-1 md:grid-cols-3'>
            <div className='col-span-2 row-span-1 flex flex-col justify-between'>
                <div className='flex flex-col gap-4'>
                    <h2 className='text-lg font-semibold text-newDesign-black'>
                        {getAppTexts().overviewPage.createWhatIfSection.title}
                    </h2>
                    <p className='text-base text-newDesign-black'>
                        {
                            getAppTexts().overviewPage.createWhatIfSection
                                .description
                        }
                    </p>
                    <div className='mb-2 flex flex-wrap gap-2'>
                        {getAppTexts().overviewPage.createWhatIfSection.bulletPoints.map(
                            (item) => (
                                <p
                                    className='text-nowrap rounded-md border bg-white px-2 py-1'
                                    key={item}>
                                    {item}
                                </p>
                            ),
                        )}
                    </div>
                </div>
                <div className='mt-1 flex w-full flex-col gap-4 sm:w-fit sm:flex-row'>
                    <Button onClick={() => setOpenCreateDialog(true)}>
                        Create New
                    </Button>

                    <CreateScenarioDialog
                        isModalOpen={openCreateDialog}
                        closeModal={() => setOpenCreateDialog(false)}
                        handleCreate={confirmCreateScenario}
                    />
                </div>
            </div>
            <div className='col-span-1 row-span-1 hidden h-full w-full items-center justify-center md:flex'>
                <LatestUpdateIllustration />
            </div>
        </div>
    );
};

export default CreateWhatIfCard;
