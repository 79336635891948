import React from 'react';
import { RiDeleteBin2Line } from 'react-icons/ri';

import { ModelGroup } from '@bae/data-interface';

import { Button } from '@/components/ui/button.tsx';
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog.tsx';
import { Separator } from '@/components/ui/separator.tsx';

const DeleteScenarioDialog = ({
    selectedScenarios,
    isModalOpen,
    closeModal,
    handleDelete,
}: {
    selectedScenarios: ModelGroup[];
    isModalOpen: boolean;
    closeModal: () => void;
    handleDelete: () => void;
}) => {
    const onDeleteClick = () => {
        handleDelete();
        closeModal();
    };

    return (
        <Dialog open={isModalOpen} onOpenChange={closeModal}>
            <DialogContent className='max-w-sm'>
                <DialogHeader>
                    <DialogTitle className='flex items-center'>
                        <RiDeleteBin2Line className='mr-2 size-5 text-newDesign-error-dark' />
                        Delete scenario
                    </DialogTitle>
                    <DialogDescription className='text-newDesign-text-secondary'>
                        Are you sure you want to delete these scenarios?
                    </DialogDescription>
                </DialogHeader>

                <ul className='list-disc pl-5'>
                    {selectedScenarios.map(({ name, id }) => (
                        <li className='[word-break:break-word]' key={id}>
                            {name}
                        </li>
                    ))}
                </ul>
                <Separator orientation='horizontal' />
                <DialogFooter className='items-center'>
                    <Button
                        onClick={closeModal}
                        variant='text'
                        className='text-newDesign-text-secondary'>
                        Cancel
                    </Button>
                    <Button onClick={onDeleteClick}>Delete</Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};

export default DeleteScenarioDialog;
