/// <reference types="vite-plugin-svgr/client" />
import { create } from 'zustand';

import React, { ReactNode, useState, ReactElement } from 'react';
import { IoIosArrowForward } from 'react-icons/io';
import { LuArrowRightToLine, LuUser } from 'react-icons/lu';
import { PiGearLight } from 'react-icons/pi';
import { TiArrowSortedDown } from 'react-icons/ti';
import { NavLink, useHistory } from 'react-router-dom';

import { useAuthConfig, useUser } from '@bae/auth';
import { RouteMap } from '@bae/routes';

import BaeLogo from '@/assets/BaeLogo.svg';
import { Avatar, AvatarFallback } from '@/components/ui/avatar.tsx';
import { Button } from '@/components/ui/button.tsx';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuTrigger,
    DropdownMenuItem,
} from '@/components/ui/dropdown.tsx';
import { Separator } from '@/components/ui/separator.tsx';
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from '@/components/ui/tooltip.tsx';
import { cn } from '@/lib/utils.ts';

interface SideBarState {
    open: boolean;
    toggle: (val: boolean) => void;
}

export const useSideBarStore = create<SideBarState>((set) => ({
    open: false,
    toggle: (open: boolean) => set({ open }),
}));

const ToggleButton = () => {
    const { open, toggle } = useSideBarStore();
    const handleClick = () => {
        toggle(!open);
    };

    return (
        <button
            className={`absolute -right-[5px] top-[40px] cursor-pointer`}
            onClick={handleClick}>
            <IoIosArrowForward
                className={`size-[20px] rounded-md border-[1px] border-solid border-transparent bg-[#372F70] text-white transition-all duration-300 ease-in-out hover:border-[#372F70] hover:bg-white hover:text-[#372F70] ${open ? 'rotate-180' : ''}`}
            />
        </button>
    );
};

const LogoButton = ({ logoNavigateTo }: { logoNavigateTo: string }) => {
    const { open } = useSideBarStore();
    const history = useHistory();

    function handleNavigate() {
        history.push(logoNavigateTo || '/');
    }

    return (
        <button
            className={`mb-4 flex h-12 w-full items-center justify-start rounded-md text-white transition-all duration-300 ease-in-out hover:bg-white hover:bg-opacity-10`}
            onClick={handleNavigate}>
            <div className='flex items-center'>
                <span className='flex aspect-square min-w-12 items-center justify-center'>
                    <BaeLogo />
                </span>

                <p
                    className={`overflow-hidden text-nowrap text-xl font-semibold transition-all duration-300 ease-in-out ${open ? 'max-w-[200px]' : 'max-w-0'}`}>
                    BAE
                </p>
            </div>
        </button>
    );
};

const ProfileMenu = ({ logOut }: { logOut: () => Promise<void> }) => {
    const { user } = useUser();
    const history = useHistory();

    function handleNavigate() {
        history.push(RouteMap.accountSettings.path);
    }

    return (
        <div className='flex w-fit flex-col justify-center gap-2 rounded-[10px] bg-newDesign-primary-dark p-4'>
            <div className='flex gap-2'>
                <Avatar className='size-8 rounded-full bg-newDesign-error'>
                    <AvatarFallback className='bg-newDesign-error'>
                        <LuUser className='size-5 text-white' />
                    </AvatarFallback>
                </Avatar>
                <div className='flex flex-col'>
                    <p className='max-w-[250px] overflow-hidden text-ellipsis whitespace-nowrap text-sm text-white'>
                        {user?.fullname}
                    </p>
                    <p className='max-w-[250px] overflow-hidden text-ellipsis whitespace-nowrap text-xs font-light text-white'>
                        {user?.email}
                    </p>
                </div>
            </div>
            <DropdownMenuItem
                onClick={handleNavigate}
                className='flex w-full justify-start bg-transparent text-sm text-white hover:bg-white hover:bg-opacity-10 active:bg-white active:bg-opacity-20'>
                <PiGearLight className='mr-2 size-5 text-white' />
                Account Settings
            </DropdownMenuItem>
            <Separator className='bg-opacity-50' />
            <Button
                onClick={logOut}
                variant='text'
                withIcon
                className='flex w-full justify-start bg-transparent text-sm text-white hover:bg-white hover:bg-opacity-10 active:bg-white active:bg-opacity-20'>
                <LuArrowRightToLine className='mr-2 size-5 text-white' />
                Log Out
            </Button>
        </div>
    );
};

const ProfileButton = () => {
    const { open } = useSideBarStore();
    const { user } = useUser();
    const { signOut } = useAuthConfig();
    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <button
                    className={`relative flex h-12 w-full min-w-12 items-center justify-start rounded-md transition-all duration-300 ease-in-out ${open ? 'max-w-[180px]' : 'max-w-12'} bg-transparent text-sm text-white hover:bg-white hover:bg-opacity-10 active:bg-white active:bg-opacity-20`}>
                    <span className='flex aspect-square min-w-12 items-center justify-center'>
                        <Avatar className='size-8 rounded-full bg-newDesign-error'>
                            <AvatarFallback className='bg-newDesign-error'>
                                <LuUser className='size-5 text-white' />
                            </AvatarFallback>
                        </Avatar>
                    </span>
                    <div
                        className={`transition-all duration-300 ease-in-out ${open ? 'max-w-[300px]' : 'max-w-0'}`}>
                        <div className='flex flex-col items-start'>
                            <p
                                className={`overflow-hidden text-ellipsis whitespace-nowrap text-sm text-white transition-all duration-300 ease-in-out ${open ? 'max-w-[120px]' : 'max-w-0'}`}>
                                {user?.fullname}
                            </p>
                            <p
                                className={`overflow-hidden text-ellipsis whitespace-nowrap text-xs font-light text-white transition-all duration-300 ease-in-out ${open ? 'max-w-[120px]' : 'max-w-0'}`}>
                                {user?.email}
                            </p>
                        </div>
                    </div>
                    {open && (
                        <TiArrowSortedDown className='absolute right-0 size-3 -rotate-90' />
                    )}
                </button>
            </DropdownMenuTrigger>
            <DropdownMenuContent
                className='border-none bg-transparent p-0 shadow-none'
                side='right'
                align='end'
                sideOffset={24}>
                <ProfileMenu logOut={signOut} />
            </DropdownMenuContent>
        </DropdownMenu>
    );
};

const SideBar = ({
    children,
    logoNavigateTo,
}: {
    children: ReactNode;
    logoNavigateTo?: string;
}) => {
    const { open } = useSideBarStore();

    return (
        <div className='relative p-1'>
            <ToggleButton />
            <aside
                aria-label='Sidebar'
                className={`flex max-h-[calc(100dvh-0.5rem)] flex-col items-center bg-newDesign-primary-dark px-4 pt-6 ${open ? 'w-[220px]' : 'w-[80px]'} min-h-full overflow-y-auto rounded-lg transition-all duration-300 ease-in-out`}>
                <LogoButton logoNavigateTo={logoNavigateTo} />
                <nav className='mb-[90px] flex h-full w-full flex-col gap-4 overflow-y-auto px-1'>
                    {children}
                </nav>
                <div
                    className={`absolute bottom-6 flex items-center justify-center transition-all duration-300 ease-in-out ${open ? 'w-[180px]' : 'w-[80px]'}`}>
                    <ProfileButton />
                </div>
            </aside>
        </div>
    );
};

const Link = ({
    icon: IconComp = <></>,
    className,
    text,
    to,
}: {
    icon?: ReactElement;
    className?: React.ComponentProps<'div'>['className'];
    text: string;
    to: string;
}) => {
    const { open } = useSideBarStore();
    const [openTooltip, setOpenTooltip] = useState(false);

    const handleTooltipOpenChange = (isOpen: boolean) => {
        if (open) return;
        setOpenTooltip(isOpen);
    };

    return (
        <TooltipProvider delayDuration={300}>
            <Tooltip open={openTooltip} onOpenChange={handleTooltipOpenChange}>
                <TooltipTrigger asChild>
                    <span>
                        <NavLink
                            className={(isActive) =>
                                cn(
                                    `flex h-10 w-full items-center rounded-md transition-all duration-300 ease-in-out ${open ? 'max-w-[230px]' : 'max-w-10'} text-white ${isActive ? 'bg-white bg-opacity-20' : ''} justify-start gap-2 hover:bg-white hover:bg-opacity-10 active:bg-white active:bg-opacity-20`,
                                    className,
                                )
                            }
                            to={to}>
                            <span className='flex aspect-square min-w-10 items-center justify-center'>
                                {IconComp}
                            </span>
                            <p
                                className={`overflow-hidden text-nowrap transition-all duration-300 ease-in-out ${open ? 'max-w-[200px]' : 'max-w-0'}`}>
                                {text}
                            </p>
                        </NavLink>
                    </span>
                </TooltipTrigger>
                <TooltipContent
                    side='right'
                    sideOffset={24}
                    className='bg-newDesign-tooltip'>
                    <p className='text-white'>{text}</p>
                </TooltipContent>
            </Tooltip>
        </TooltipProvider>
    );
};

SideBar.Link = Link;

export default SideBar;
