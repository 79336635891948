import { QueryConfig } from '@/libs/api/src/lib/react-query.ts';
import { queryOptions, useQuery } from '@tanstack/react-query';

import { AxiosAPIv2, getToken } from '@bae/api';

import { mistDataResponseAdapter } from '@/features/digital-twin/MistSettings/api/adapters.tsx';

import { MistDataInput, MistDataResponse } from '../types.ts';

interface GetMistInputParams {
    groupId: string;
}

export const getMistInput = async ({
    groupId,
}: GetMistInputParams): Promise<MistDataInput> => {
    const response = await AxiosAPIv2.axiosInstance.get<{
        group_input: MistDataResponse;
    }>(`/partners/networkvendor/model/${groupId}`, {
        headers: { Authorization: 'Bearer ' + getToken() },
    });

    return mistDataResponseAdapter(response.data.group_input);
};

export const getMistInputQueryOptions = ({ groupId }: GetMistInputParams) => {
    return queryOptions({
        queryKey: ['models', groupId],
        queryFn: () => getMistInput({ groupId }),
    });
};

type UseGetMistInputOptions = {
    groupId: string;
    queryConfig?: QueryConfig<typeof getMistInputQueryOptions>;
    enabled?: boolean;
};

export const useGetMistInput = ({
    queryConfig,
    groupId,
    enabled = true,
}: UseGetMistInputOptions) => {
    return useQuery({
        ...getMistInputQueryOptions({ groupId }),
        ...queryConfig,
        enabled,
    });
};
