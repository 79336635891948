import { Check, ChevronsUpDown } from 'lucide-react';
import { useState } from 'react';

import TooltipWrapper from '@/components/ui/TooltipWrapper.tsx';
import { Button } from '@/components/ui/button.tsx';
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
} from '@/components/ui/command.tsx';
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from '@/components/ui/popover.tsx';
import { Device } from '@/features/digital-twin/MistSettings/types.ts';
import { cn } from '@/lib/utils.ts';

const AccessPointCombobox = ({
    value,
    onChange,
    disabled,
    accessPointsModels,
}: {
    value: string;
    onChange: (value: string) => void;
    accessPointsModels?: Device[];
    disabled?: boolean;
}) => {
    const [open, setOpen] = useState(false);

    const buttonText = value
        ? accessPointsModels?.find(({ sku }) => sku === value)?.sku
        : 'Select an access point';

    return (
        <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger asChild>
                <Button
                    disabled={disabled}
                    variant='outline'
                    role='combobox'
                    aria-expanded={open}
                    className={cn(
                        'border-divider w-full justify-between text-sm font-normal text-newDesign-text-secondary',
                        {
                            'text-newDesign-text-primary':
                                value && value?.length > 1,
                        },
                    )}>
                    <TooltipWrapper text={buttonText}>
                        <p className='overflow-hidden text-ellipsis whitespace-nowrap'>
                            {buttonText}
                        </p>
                    </TooltipWrapper>
                    <ChevronsUpDown className='size-5 opacity-50' />
                </Button>
            </PopoverTrigger>

            {/* https://github.com/shadcn-ui/ui/issues/1690#issuecomment-2016635419 */}
            <PopoverContent className='max-h-[--radix-popover-content-available-height] min-w-fit p-0'>
                <Command value={value}>
                    <CommandInput placeholder='Search access points' />
                    <CommandList>
                        <CommandEmpty>No access point found.</CommandEmpty>
                        <CommandGroup>
                            {accessPointsModels?.map(({ sku }) => (
                                <CommandItem
                                    key={sku}
                                    value={sku}
                                    onSelect={(currentValue) => {
                                        onChange(currentValue);
                                        setOpen(false);
                                    }}>
                                    {sku}
                                    <Check
                                        className={cn(
                                            'ml-auto',
                                            value === sku
                                                ? 'opacity-100'
                                                : 'opacity-0',
                                        )}
                                    />
                                </CommandItem>
                            ))}
                        </CommandGroup>
                    </CommandList>
                </Command>
            </PopoverContent>
        </Popover>
    );
};

export default AccessPointCombobox;
