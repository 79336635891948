import { z } from 'zod';

import React, { useEffect, FC } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { useCurrency } from '@bae/store';

import { Button } from '@/components/ui/button.tsx';
import {
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog.tsx';
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '@/components/ui/form.tsx';
import { Input } from '@/components/ui/input.tsx';
import useMistStore from '@/features/digital-twin/MistSettings/store';
import { FteInput } from '@/features/digital-twin/MistSettings/types.ts';
import { cn } from '@/lib/utils.ts';

import { zodResolver } from '@hookform/resolvers/zod';

const formSchema = z.object({
    fteName: z
        .string()
        .min(2, 'Name must contain at least 2 characters')
        .max(50, 'Name must be at most 50 characters long'),
    annualExpense: z.string().min(1),
    annualExpenseCagr: z.string().min(1),
    quantity: z.string().min(1),
});

interface Props {
    isOpen: boolean;
    closeModal: () => void;
    fte?: FteInput;
}

const defaultValues = {
    fteName: '',
    annualExpense: '0',
    annualExpenseCagr: '0',
    quantity: '0',
};

const FteDialog: FC<Props> = ({ isOpen, closeModal, fte }) => {
    const { currency } = useCurrency();

    const updateFte = useMistStore((state) => state.updateFte);
    const addFte = useMistStore((state) => state.addFte);

    const ftes = useMistStore((state) => state.data.ftes);

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: fte
            ? {
                  fteName: fte.fteName,
                  annualExpense: fte.annualExpense,
                  annualExpenseCagr: fte.annualExpenseCagr,
                  quantity: fte.quantity,
              }
            : defaultValues,
    });

    function onSubmit(values: z.infer<typeof formSchema>) {
        if (fte) {
            const isDuplicate = ftes.find(
                (fte) =>
                    fte.fteName === values.fteName &&
                    values.fteName !== fte.fteName,
            );

            if (isDuplicate) {
                toast.error('Duplicate FTEs are not allowed.');
                return;
            }

            updateFte(fte, {
                ...values,
                status: fte.status === 'original' ? 'modified' : fte.status,
                description: '',
            } as FteInput);
        } else {
            const isDuplicate = ftes.find(
                (fte) => fte.fteName === values.fteName,
            );

            if (isDuplicate) {
                toast.error('Duplicate FTEs are not allowed.');
                return;
            }

            addFte({
                ...values,
                status: 'added',
                description: '',
            } as FteInput);
        }

        closeModal();
    }

    useEffect(() => {
        if (isOpen) {
            form.reset();
        }
    }, [form, isOpen]);

    return (
        <DialogContent className='sm:max-w-[400px]'>
            <DialogHeader>
                <DialogTitle>New FTE</DialogTitle>
                <DialogDescription>
                    Fill in the fields below to add a new FTE.
                </DialogDescription>
            </DialogHeader>
            <Form {...form}>
                <form
                    onSubmit={form.handleSubmit(onSubmit)}
                    className='grid grid-cols-1 gap-4'>
                    <FormField
                        control={form.control}
                        name='fteName'
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>FTE</FormLabel>
                                <FormControl>
                                    <Input
                                        placeholder='Enter the FTE name...'
                                        {...field}
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />

                    <FormField
                        control={form.control}
                        name='annualExpense'
                        render={({ field }) => {
                            const { onChange, ...rest } = field;

                            return (
                                <FormItem>
                                    <FormLabel>
                                        Fully Loaded Annual Expense
                                    </FormLabel>
                                    <FormControl>
                                        <CurrencyInput
                                            className={cn(
                                                'h-10 w-full rounded-md border px-3 py-2 focus-visible:outline-none',
                                                'focus-visible:ring-2 focus-visible:ring-neutral-950 focus-visible:ring-offset-2',
                                            )}
                                            intlConfig={{
                                                locale: 'en-US',
                                                currency,
                                            }}
                                            decimalsLimit={8}
                                            defaultValue={0}
                                            onValueChange={(value) => {
                                                onChange(value || '0');
                                            }}
                                            {...rest}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            );
                        }}
                    />

                    <div className='grid grid-cols-2 gap-4'>
                        <FormField
                            control={form.control}
                            name='annualExpenseCagr'
                            render={({ field }) => {
                                const { onChange, ...rest } = field;

                                return (
                                    <FormItem>
                                        <FormLabel>Wage Growth Rate</FormLabel>
                                        <FormControl>
                                            <CurrencyInput
                                                className={cn(
                                                    'h-10 w-full rounded-md border px-3 py-2 focus-visible:outline-none',
                                                    'focus-visible:ring-2 focus-visible:ring-neutral-950 focus-visible:ring-offset-2',
                                                )}
                                                intlConfig={{ locale: 'en-US' }}
                                                suffix=' %'
                                                decimalsLimit={4}
                                                defaultValue={0}
                                                onValueChange={(value) => {
                                                    if (
                                                        parseFloat(value) > 100
                                                    ) {
                                                        return onChange('100');
                                                    }

                                                    onChange(value || '0');
                                                }}
                                                {...rest}
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                );
                            }}
                        />

                        <FormField
                            control={form.control}
                            name='quantity'
                            render={({ field }) => {
                                const { onChange, ...rest } = field;

                                return (
                                    <FormItem>
                                        <FormLabel>Quantity</FormLabel>
                                        <FormControl>
                                            <CurrencyInput
                                                className={cn(
                                                    'h-10 w-full rounded-md border px-3 py-2 focus-visible:outline-none',
                                                    'focus-visible:ring-2 focus-visible:ring-neutral-950 focus-visible:ring-offset-2',
                                                )}
                                                intlConfig={{ locale: 'en-US' }}
                                                decimalsLimit={8}
                                                allowNegativeValue={false}
                                                defaultValue={0}
                                                onValueChange={(value) => {
                                                    onChange(value || '0');
                                                }}
                                                {...rest}
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                );
                            }}
                        />
                    </div>

                    <DialogFooter>
                        <Button
                            onClick={closeModal}
                            type='button'
                            variant='outline'>
                            Cancel
                        </Button>
                        <Button type='submit'>Add</Button>
                    </DialogFooter>
                </form>
            </Form>
        </DialogContent>
    );
};

export default FteDialog;
