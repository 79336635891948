import React, { useState } from 'react';
import { IoIosArrowForward } from 'react-icons/io';
import { TbArrowsDiff } from 'react-icons/tb';
import { NavLink } from 'react-router-dom';

import { Button } from '@/components/ui/button.tsx';
import { useListMistModels } from '@/features/digital-twin/MistSettings/api/list-mist-models.ts';
import CompareDialog from '@/features/scenario/components/CompareDialog.tsx';

import ScenariosContent from './ScenariosContent.tsx';

const ScenariosCreatedCard = () => {
    const [openCompareModal, setOpenCompareModal] = useState(false);

    const scenarioQuery = useListMistModels();

    const slicedScenarios =
        scenarioQuery.data
            ?.sort((a, b) => {
                return (
                    new Date(b.models[0].lastUpdateAt).valueOf() -
                    new Date(a.models[0].lastUpdateAt).valueOf()
                );
            })
            .slice(0, 3) || [];

    return (
        <div className='flex h-full w-full flex-col gap-4'>
            <div className='flex justify-between'>
                <h2 className='text-lg font-semibold text-newDesign-black'>
                    Scenarios
                </h2>
                <CompareDialog
                    isModalOpen={openCompareModal}
                    closeModal={() => setOpenCompareModal(false)}
                />
                <Button
                    onClick={() => setOpenCompareModal(true)}
                    size='sm'
                    withIcon>
                    <TbArrowsDiff className='mr-2 size-5' />
                    Compare
                </Button>
            </div>
            <div>
                <p className='mb-2 '>
                    Different scenarios can be created for “What if” analysis.
                </p>
                {/*<p className='mb-2 '>*/}
                {/*    Different scenarios can be created from the real-time*/}
                {/*    digital twin for “What if” analysis.*/}
                {/*</p>*/}
                <p className='mb-2'>Scenarios can be used to examine:</p>
                <div className='flex flex-wrap gap-2'>
                    <p className='text-nowrap rounded-md border bg-white px-2 py-1'>
                        Different traffic growth rates
                    </p>
                    <p className='text-nowrap rounded-md border bg-white px-2 py-1'>
                        Changes in network configurations
                    </p>
                    <p className='text-nowrap rounded-md border bg-white px-2 py-1'>
                        Adding new sites
                    </p>
                    <p className='text-nowrap rounded-md border bg-white px-2 py-1'>
                        Adding new services
                    </p>
                    <p className='text-nowrap rounded-md border bg-white px-2 py-1'>
                        Sensitivity analysis
                    </p>
                    <p className='text-nowrap rounded-md border bg-white px-2 py-1'>
                        Others…
                    </p>
                </div>
                {/*<p className='mt-2'>*/}
                {/*    New scenarios can be created in the digital twin dashboard*/}
                {/*</p>*/}
            </div>
            <div className='flex w-full flex-1 flex-col gap-2'>
                <ScenariosContent
                    loading={scenarioQuery.isLoading}
                    scenarios={slicedScenarios}
                />
            </div>

            {!scenarioQuery.isLoading &&
                scenarioQuery.data &&
                scenarioQuery.data.length > 0 && (
                    <div className='flex w-full justify-end'>
                        <NavLink to='scenarios'>
                            <Button
                                withIcon
                                size='sm'
                                variant='text'
                                className='h-fit bg-transparent p-1 pl-2 text-xs hover:bg-white hover:text-newDesign-primary'>
                                See all
                                <IoIosArrowForward className='ml-2 size-3' />
                            </Button>
                        </NavLink>
                    </div>
                )}
        </div>
    );
};

export default ScenariosCreatedCard;
