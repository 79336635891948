import { z } from 'zod';

import React, { useEffect, FC, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { useForm } from 'react-hook-form';
import { PiWarningThin } from 'react-icons/pi';
import { toast } from 'react-toastify';

import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert.tsx';
import { Button } from '@/components/ui/button.tsx';
import {
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog.tsx';
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '@/components/ui/form.tsx';
import { Input } from '@/components/ui/input.tsx';
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@/components/ui/select.tsx';
import { Skeleton } from '@/components/ui/skeleton.tsx';
import { useGetMistDevices } from '@/features/digital-twin/MistSettings/api/get-mist-devices.ts';
import AccessPointCombobox from '@/features/digital-twin/MistSettings/components/AccessPoints/components/AccessPointCombobox.tsx';
import useMistStore from '@/features/digital-twin/MistSettings/store';
import { cn } from '@/lib/utils.ts';

import type { AccessPointInput } from '../../../types.ts';
import DelayedDeploymentTable from '../../shared/DelayedDeployment/DelayedDeploymentTable.tsx';
import { zodResolver } from '@hookform/resolvers/zod';

const formSchema = z.object({
    name: z
        .string()
        .min(2, 'Name must contain at least 2 characters')
        .max(50, 'Name must be at most 50 characters long'),
    site: z.string().min(1, 'You must select a site'),
    sku: z.string().min(1, 'You must select a model'),
    trafficMbps: z.string().min(1),
    clientsPerSite: z.string().min(1),
    avgTrafficPerAP: z.string().min(1),
    avgClientsPerAP: z.string().min(1),
    trafficGrowth: z.string().min(1),
    clientGrowth: z.string().min(1),
});

interface Props {
    isOpen: boolean;
    closeModal: () => void;
    accessPoint?: AccessPointInput;
}

const defaultValues = {
    name: '',
    site: '',
    sku: '',
    trafficMbps: '0',
    clientsPerSite: '0',
    avgTrafficPerAP: '0',
    avgClientsPerAP: '0',
    trafficGrowth: '0',
    clientGrowth: '0',
};

const defaultDelayedDeployments = [
    {
        delay: 0,
        quantity: 1,
    },
];

const AccessPointDialog: FC<Props> = ({ isOpen, closeModal, accessPoint }) => {
    const addAccessPoint = useMistStore((state) => state.addAccessPoint);
    const updateAccessPoint = useMistStore((state) => state.updateAccessPoint);

    const sites = useMistStore((state) => state.data.sites);
    const accessPoints = useMistStore((state) => state.data.accessPoints);
    const optionalLicenses = useMistStore(
        (state) => state.data.optionalLicenses,
    );

    const apLicensing = optionalLicenses.filter(
        ({ vendor }) => vendor === 'juniper/mist',
    )[0].aps;

    const [delayedDeployments, setDelayedDeployments] = useState(
        defaultDelayedDeployments,
    );

    const emptySites = sites.length === 0;

    useEffect(() => {
        if (accessPoint?.qtyTimeSeries) {
            setDelayedDeployments(accessPoint.qtyTimeSeries);
        }
    }, [accessPoint, isOpen]);

    const { data: accessPointsModels, isFetching: isFetchingApModels } =
        useGetMistDevices({ deviceType: 'ap' });

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        disabled: emptySites,
        defaultValues: accessPoint
            ? {
                  name: accessPoint.name,
                  site: accessPoint.site,
                  sku: accessPoint.sku,
                  trafficMbps: accessPoint.trafficMbps,
                  clientsPerSite: accessPoint.clientsPerSite,
                  avgTrafficPerAP: accessPoint.avgTrafficPerAP,
                  avgClientsPerAP: accessPoint.avgClientsPerAP,
                  trafficGrowth: accessPoint.trafficGrowth,
                  clientGrowth: accessPoint.clientGrowth,
              }
            : defaultValues,
    });

    function onSubmit(values: z.infer<typeof formSchema>) {
        if (accessPoint) {
            const isDuplicate = accessPoints.find(
                ({ name }) =>
                    name.toLowerCase() === values.name.toLowerCase() &&
                    values.name.toLowerCase() !==
                        accessPoint.name.toLowerCase(),
            );

            if (isDuplicate) {
                toast.error('Duplicate access points are not allowed.');
                return;
            }

            updateAccessPoint(accessPoint, {
                ...values,
                vendor: 'juniper/mist',
                licenses: apLicensing.licenses,
                licensing: {
                    term: apLicensing.term,
                    total_number: apLicensing.total_number,
                    expirationTime: null,
                },
                expense: 'capex',
                group: [],
                qtyTimeSeries: delayedDeployments,
                status:
                    accessPoint.status === 'original'
                        ? 'modified'
                        : accessPoint.status,
            } as AccessPointInput);
        } else {
            const isDuplicate = accessPoints.find(
                ({ name }) => name.toLowerCase() === values.name.toLowerCase(),
            );

            if (isDuplicate) {
                toast.error('Duplicate access points are not allowed.');
                return;
            }

            addAccessPoint({
                ...values,
                vendor: 'juniper/mist',
                licenses: apLicensing.licenses,
                licensing: {
                    term: apLicensing.term,
                    total_number: apLicensing.total_number,
                    expirationTime: null,
                },
                group: [],
                expense: 'capex',
                qtyTimeSeries: delayedDeployments,
                status: 'added',
            } as AccessPointInput);
        }

        closeModal();
    }

    useEffect(() => {
        if (!isOpen) {
            form.reset();
            setDelayedDeployments(
                accessPoint
                    ? accessPoint.qtyTimeSeries
                    : defaultDelayedDeployments,
            );
        }
    }, [accessPoint, form, isOpen]);

    const getApModelDescription = (sku: string) => {
        return accessPointsModels?.find((s) => s.sku === sku)?.description;
    };

    const selectedModel = form.watch('sku');

    return (
        <DialogContent className='max-h-dvh max-w-[500px] overflow-y-auto sm:max-w-[1000px]'>
            <DialogHeader>
                <DialogTitle>New Access Point</DialogTitle>
                <DialogDescription>
                    Fill in the fields below to add a new AP.
                </DialogDescription>
            </DialogHeader>
            <div className='relative grid grid-cols-1 gap-8 lg:grid-cols-2'>
                {emptySites && (
                    <div className='absolute bottom-[-8px] left-[-8px] right-[-8px] top-[-8px] z-[60] flex items-center justify-center rounded-lg bg-newDesign-divider bg-opacity-75 p-40'>
                        <Alert>
                            <PiWarningThin className='size-4' />

                            <AlertTitle>Sites missing!</AlertTitle>
                            <AlertDescription>
                                Create a Site first to enable adding Access
                                Points
                            </AlertDescription>
                        </Alert>
                    </div>
                )}

                <Form {...form}>
                    <form
                        onSubmit={form.handleSubmit(onSubmit)}
                        className='grid grid-cols-1 gap-4 md:grid-cols-2'>
                        <FormField
                            control={form.control}
                            name='name'
                            render={({ field }) => (
                                <FormItem className='col-span-1 md:col-span-2'>
                                    <FormLabel>Access Point</FormLabel>
                                    <FormControl>
                                        <Input
                                            placeholder='Enter the AP name...'
                                            {...field}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name='site'
                            render={({ field }) => {
                                const { onChange, ...rest } = field;

                                return (
                                    <FormItem>
                                        <FormLabel>Site</FormLabel>
                                        <FormControl>
                                            <Select
                                                onValueChange={onChange}
                                                {...rest}>
                                                <SelectTrigger>
                                                    <SelectValue placeholder='Select site...' />
                                                </SelectTrigger>
                                                <SelectContent>
                                                    <SelectGroup>
                                                        {sites.map(
                                                            ({ site }) => (
                                                                <SelectItem
                                                                    key={site}
                                                                    value={
                                                                        site
                                                                    }>
                                                                    {site}
                                                                </SelectItem>
                                                            ),
                                                        )}
                                                    </SelectGroup>
                                                </SelectContent>
                                            </Select>
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                );
                            }}
                        />

                        <FormField
                            control={form.control}
                            name='sku'
                            render={({
                                field: { onChange, value, disabled },
                            }) => {
                                return (
                                    <FormItem>
                                        <FormLabel>Model</FormLabel>
                                        <FormControl>
                                            <AccessPointCombobox
                                                value={value}
                                                onChange={onChange}
                                                accessPointsModels={
                                                    accessPointsModels
                                                }
                                                disabled={
                                                    disabled ||
                                                    isFetchingApModels
                                                }
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                );
                            }}
                        />

                        {selectedModel.length > 0 && (
                            <div className='col-span-1 my-1 w-full md:col-span-2'>
                                <p className='mb-0.5 text-xs text-newDesign-text-secondary'>
                                    Model description:
                                </p>
                                <p className='text-xs italic text-newDesign-text-secondary'>
                                    {getApModelDescription(selectedModel)}
                                </p>
                            </div>
                        )}

                        {/*<FormField*/}
                        {/*    control={form.control}*/}
                        {/*    name='trafficMbps'*/}
                        {/*    render={({ field }) => {*/}
                        {/*        const { onChange, ...rest } = field;*/}

                        {/*        return (*/}
                        {/*            <FormItem>*/}
                        {/*                <FormLabel>*/}
                        {/*                    Site Traffic (Mbps)*/}
                        {/*                </FormLabel>*/}
                        {/*                <FormControl>*/}
                        {/*                    <CurrencyInput*/}
                        {/*                        className={cn(*/}
                        {/*                            'h-10 w-full rounded-md border px-3 py-2 focus-visible:outline-none',*/}
                        {/*                            'focus-visible:ring-2 focus-visible:ring-neutral-950 focus-visible:ring-offset-2',*/}
                        {/*                        )}*/}
                        {/*                        intlConfig={{ locale: 'en-US' }}*/}
                        {/*                        decimalsLimit={8}*/}
                        {/*                        allowNegativeValue={false}*/}
                        {/*                        defaultValue={0}*/}
                        {/*                        onValueChange={(value) => {*/}
                        {/*                            onChange(value || '0');*/}
                        {/*                        }}*/}
                        {/*                        {...rest}*/}
                        {/*                    />*/}
                        {/*                </FormControl>*/}
                        {/*                <FormMessage />*/}
                        {/*            </FormItem>*/}
                        {/*        );*/}
                        {/*    }}*/}
                        {/*/>*/}

                        {/*<FormField*/}
                        {/*    control={form.control}*/}
                        {/*    name='clientsPerSite'*/}
                        {/*    render={({ field }) => {*/}
                        {/*        const { onChange, ...rest } = field;*/}

                        {/*        return (*/}
                        {/*            <FormItem>*/}
                        {/*                <FormLabel>*/}
                        {/*                    Wireless Clients per Site*/}
                        {/*                </FormLabel>*/}
                        {/*                <FormControl>*/}
                        {/*                    <CurrencyInput*/}
                        {/*                        className={cn(*/}
                        {/*                            'h-10 w-full rounded-md border px-3 py-2 focus-visible:outline-none',*/}
                        {/*                            'focus-visible:ring-2 focus-visible:ring-neutral-950 focus-visible:ring-offset-2',*/}
                        {/*                        )}*/}
                        {/*                        intlConfig={{ locale: 'en-US' }}*/}
                        {/*                        allowDecimals={false}*/}
                        {/*                        allowNegativeValue={false}*/}
                        {/*                        defaultValue={0}*/}
                        {/*                        onValueChange={(value) => {*/}
                        {/*                            onChange(value || '0');*/}
                        {/*                        }}*/}
                        {/*                        {...rest}*/}
                        {/*                    />*/}
                        {/*                </FormControl>*/}
                        {/*                <FormMessage />*/}
                        {/*            </FormItem>*/}
                        {/*        );*/}
                        {/*    }}*/}
                        {/*/>*/}

                        <FormField
                            control={form.control}
                            name='avgTrafficPerAP'
                            render={({ field }) => {
                                const { onChange, ...rest } = field;

                                return (
                                    <FormItem>
                                        <FormLabel>
                                            Average Traffic per AP (Mbps)
                                        </FormLabel>
                                        <FormControl>
                                            <CurrencyInput
                                                className={cn(
                                                    'h-10 w-full rounded-md border px-3 py-2 focus-visible:outline-none',
                                                    'focus-visible:ring-2 focus-visible:ring-neutral-950 focus-visible:ring-offset-2',
                                                )}
                                                intlConfig={{ locale: 'en-US' }}
                                                decimalsLimit={8}
                                                allowNegativeValue={false}
                                                defaultValue={0}
                                                onValueChange={(value) => {
                                                    onChange(value || '0');
                                                }}
                                                {...rest}
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                );
                            }}
                        />

                        <FormField
                            control={form.control}
                            name='avgClientsPerAP'
                            render={({ field }) => {
                                const { onChange, ...rest } = field;

                                return (
                                    <FormItem>
                                        <FormLabel>
                                            Average Wireless Clients per AP
                                        </FormLabel>
                                        <FormControl>
                                            <CurrencyInput
                                                className={cn(
                                                    'h-10 w-full rounded-md border px-3 py-2 focus-visible:outline-none',
                                                    'focus-visible:ring-2 focus-visible:ring-neutral-950 focus-visible:ring-offset-2',
                                                )}
                                                intlConfig={{ locale: 'en-US' }}
                                                allowDecimals={false}
                                                allowNegativeValue={false}
                                                defaultValue={0}
                                                onValueChange={(value) => {
                                                    onChange(value || '0');
                                                }}
                                                {...rest}
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                );
                            }}
                        />

                        <FormField
                            control={form.control}
                            name='trafficGrowth'
                            render={({ field }) => {
                                const { onChange, ...rest } = field;

                                return (
                                    <FormItem>
                                        <FormLabel>
                                            Traffic Growth Rate
                                        </FormLabel>
                                        <FormControl>
                                            <CurrencyInput
                                                className={cn(
                                                    'h-10 w-full rounded-md border px-3 py-2 focus-visible:outline-none',
                                                    'focus-visible:ring-2 focus-visible:ring-neutral-950 focus-visible:ring-offset-2',
                                                )}
                                                intlConfig={{ locale: 'en-US' }}
                                                suffix=' %'
                                                decimalsLimit={4}
                                                defaultValue={0}
                                                onValueChange={(value) => {
                                                    if (
                                                        parseFloat(value) > 100
                                                    ) {
                                                        return onChange('100');
                                                    }

                                                    onChange(value || '0');
                                                }}
                                                {...rest}
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                );
                            }}
                        />

                        <FormField
                            control={form.control}
                            name='clientGrowth'
                            render={({ field }) => {
                                const { onChange, ...rest } = field;

                                return (
                                    <FormItem>
                                        <FormLabel>
                                            Wireless Client Growth Rate
                                        </FormLabel>
                                        <FormControl>
                                            <CurrencyInput
                                                className={cn(
                                                    'h-10 w-full rounded-md border px-3 py-2 focus-visible:outline-none',
                                                    'focus-visible:ring-2 focus-visible:ring-neutral-950 focus-visible:ring-offset-2',
                                                )}
                                                intlConfig={{ locale: 'en-US' }}
                                                suffix=' %'
                                                decimalsLimit={4}
                                                defaultValue={0}
                                                onValueChange={(value) => {
                                                    if (
                                                        parseFloat(value) > 100
                                                    ) {
                                                        return onChange('100');
                                                    }

                                                    onChange(value || '0');
                                                }}
                                                {...rest}
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                );
                            }}
                        />
                    </form>
                </Form>

                {emptySites ? (
                    <div className='flex w-full flex-col gap-4'>
                        <Skeleton className='h-20 w-full' />
                        <Skeleton className='size-full' />
                    </div>
                ) : (
                    <DelayedDeploymentTable
                        title='Delayed Deployment'
                        data={delayedDeployments}
                        onChange={setDelayedDeployments}
                        className='w-full'
                    />
                )}
            </div>
            <DialogFooter className='gap-4 lg:gap-0'>
                <Button onClick={closeModal} type='button' variant='outline'>
                    Cancel
                </Button>
                <Button
                    disabled={emptySites}
                    onClick={form.handleSubmit(onSubmit)}
                    type='submit'>
                    Add
                </Button>
            </DialogFooter>
        </DialogContent>
    );
};

export default AccessPointDialog;
