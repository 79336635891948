import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuTrigger,
} from '@radix-ui/react-dropdown-menu';
import React from 'react';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { GrPowerReset } from 'react-icons/gr';
import { LuLayoutDashboard, LuPencil } from 'react-icons/lu';
import { RiDeleteBin2Line } from 'react-icons/ri';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { RouteMap } from '@bae/routes';

import TooltipWrapper from '@/components/ui/TooltipWrapper.tsx';
import { Button } from '@/components/ui/button.tsx';
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog.tsx';
import { DropdownMenuItem } from '@/components/ui/dropdown.tsx';
import { Input } from '@/components/ui/input.tsx';
import { Label } from '@/components/ui/label.tsx';
import { Separator } from '@/components/ui/separator.tsx';
import { useDeleteModelGroups } from '@/features/model-groups/api/delete-model-groups.ts';
import { usePatchModelGroup } from '@/features/model-groups/api/patch-model-group.ts';

const ScenarioActions = ({
    groupId,
    modelId,
    scenarioName,
}: {
    groupId: string;
    modelId: string;
    scenarioName: string;
}) => {
    const history = useHistory();
    const [openRenameModal, setOpenRenameModal] = React.useState(false);

    const [openDeleteModal, setOpenDeleteModal] = React.useState(false);

    const renameModelQuery = usePatchModelGroup({
        mutationConfig: {
            onSuccess: () => {
                toast.success('Scenario renamed successfully', {
                    autoClose: 2000,
                });
            },
            onError: () => {
                toast.error('Failed to rename scenario');
            },
        },
    });

    const deleteModelsQuery = useDeleteModelGroups({
        mutationConfig: {
            onSuccess: () => {
                toast.success('Scenario deleted successfully', {
                    autoClose: 2000,
                });
            },
            onError: () => {
                toast.error('Failed to delete scenario');
            },
        },
    });

    const handleDelete = () => {
        deleteModelsQuery.mutate({ groupIds: [groupId] });
    };

    const handleRename = (name: string) => {
        renameModelQuery.mutate([{ group_name: name, group_id: groupId }]);
    };

    const navigateToDashboard = () => {
        history.push(`${RouteMap.scenarios.path}/${modelId}`);
    };

    const isPending = renameModelQuery.isPending || deleteModelsQuery.isPending;

    return (
        <>
            <RenameScenarioDialog
                scenarioName={scenarioName}
                handleRename={handleRename}
                closeModal={() => setOpenRenameModal(false)}
                isModalOpen={openRenameModal}
            />
            <DeleteDialog
                scenarioName={scenarioName}
                handleDelete={handleDelete}
                closeModal={() => setOpenDeleteModal(false)}
                isModalOpen={openDeleteModal}
            />
            <DropdownMenu>
                <DropdownMenuTrigger asChild>
                    {isPending ? (
                        <span className='p-1'>
                            <GrPowerReset className='size-5 animate-spin text-newDesign-primary' />
                        </span>
                    ) : (
                        <button className='rounded-md p-1  hover:bg-newDesign-text-hover'>
                            <TooltipWrapper text='Actions'>
                                <span>
                                    <BsThreeDotsVertical className='size-5 text-newDesign-primary' />
                                </span>
                            </TooltipWrapper>
                        </button>
                    )}
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                    <div className='grid w-40 grid-cols-1 rounded-md border bg-white p-2 shadow-lg'>
                        <DropdownMenuItem
                            onClick={navigateToDashboard}
                            className='w-full justify-start text-sm text-newDesign-text-primary'>
                            <LuLayoutDashboard className='mr-2 size-5 text-newDesign-primary' />
                            Dashboard
                        </DropdownMenuItem>

                        <DropdownMenuItem
                            onClick={() => setOpenRenameModal(true)}
                            className='w-full justify-start text-sm text-newDesign-text-primary'>
                            <LuPencil className='mr-2 size-5 text-newDesign-primary' />
                            Rename
                        </DropdownMenuItem>

                        <DropdownMenuItem
                            onClick={() => setOpenDeleteModal(true)}
                            className='w-full justify-start text-sm  text-newDesign-text-primary'>
                            <RiDeleteBin2Line className='mr-2 size-5 text-newDesign-error' />
                            Delete
                        </DropdownMenuItem>
                    </div>
                </DropdownMenuContent>
            </DropdownMenu>
        </>
    );
};

const RenameScenarioDialog = ({
    handleRename,
    isModalOpen,
    closeModal,
    scenarioName,
}: {
    handleRename: (name: string) => void;
    isModalOpen: boolean;
    closeModal: () => void;
    scenarioName: string;
}) => {
    const [name, setName] = React.useState(scenarioName);

    const handleOnRename = () => {
        handleRename(name);
        closeModal();
    };

    return (
        <Dialog open={isModalOpen} onOpenChange={closeModal}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Rename scenario</DialogTitle>
                </DialogHeader>
                <Label htmlFor='rename-scenario'>Scenario name</Label>
                <Input
                    id='rename-scenario'
                    placeholder='Enter a new name'
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <DialogFooter>
                    <Button onClick={closeModal} variant='text'>
                        Cancel
                    </Button>
                    <Button onClick={handleOnRename}>Save</Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};

const DeleteDialog = ({
    scenarioName,
    isModalOpen,
    closeModal,
    handleDelete,
}: {
    scenarioName: string;
    isModalOpen: boolean;
    closeModal: () => void;
    handleDelete: () => void;
}) => {
    const onDeleteClick = () => {
        handleDelete();
        closeModal();
    };

    return (
        <Dialog open={isModalOpen} onOpenChange={closeModal}>
            <DialogContent className='max-w-sm'>
                <DialogHeader>
                    <DialogTitle className='flex items-center'>
                        <RiDeleteBin2Line className='mr-2 size-8 text-newDesign-error-dark' />
                        Delete scenario
                    </DialogTitle>
                </DialogHeader>
                <p>Are you sure you want to delete this scenario?</p>
                <ul className='list-disc pl-5'>
                    <li className='[word-break:break-word]'>{scenarioName}</li>
                </ul>
                <Separator orientation='horizontal' />

                <DialogFooter className='items-center'>
                    <Button
                        onClick={closeModal}
                        variant='text'
                        className='text-newDesign-text-secondary'>
                        Cancel
                    </Button>
                    <Button onClick={onDeleteClick}>Delete</Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};

export default ScenarioActions;
