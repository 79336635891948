import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group.tsx';
import { Separator } from '@/components/ui/separator.tsx';
import useMistStore from '@/features/digital-twin/MistSettings/store';
import { ExpenseType } from '@/features/digital-twin/MistSettings/types.ts';

import CostOfCapital from '../shared/CostOfCapital.tsx';
import DepreciationTime from '../shared/DepreciationTime.tsx';
import ModelRunTime from '../shared/ModelRunTime.tsx';

const Configuration = () => {
    const configuration = useMistStore((state) => state.data.configuration);
    const updateConfiguration = useMistStore(
        (state) => state.updateConfiguration,
    );

    const licensesExpenseType = configuration.licenses;

    return (
        <section className='flex w-full flex-col gap-2'>
            <div className='flex w-full flex-col gap-4 rounded-md border border-newDesign-divider p-4'>
                <h3 className='font-semibold'>License Defaults</h3>
                <div className='flex gap-6'>
                    <div>
                        <p className='mb-4 text-xs text-newDesign-text-secondary'>
                            1 Year License
                        </p>
                        <RadioGroup
                            onValueChange={(value) => {
                                updateConfiguration('licenses', {
                                    ...licensesExpenseType,
                                    year1: value as ExpenseType,
                                });
                            }}
                            value={licensesExpenseType.year1}
                            className='flex gap-4'>
                            <div className='flex items-center gap-2'>
                                <RadioGroupItem id='1-y-capex' value='capex' />
                                <label htmlFor='1-y-capex' className='text-sm'>
                                    CapEx
                                </label>
                            </div>

                            <div className='flex items-center gap-2'>
                                <RadioGroupItem id='1-y-opex' value='opex'>
                                    OpEx
                                </RadioGroupItem>
                                <label htmlFor='1-y-opex' className='text-sm'>
                                    OpEx
                                </label>
                            </div>
                        </RadioGroup>
                    </div>

                    <Separator orientation='vertical' className='h-12' />

                    <div>
                        <p className='mb-4 text-xs text-newDesign-text-secondary'>
                            3 Year License
                        </p>
                        <RadioGroup
                            onValueChange={(value) => {
                                updateConfiguration('licenses', {
                                    ...licensesExpenseType,
                                    year3: value as ExpenseType,
                                });
                            }}
                            value={licensesExpenseType.year3}
                            className='flex gap-4'>
                            <div className='flex items-center gap-2'>
                                <RadioGroupItem id='3-y-capex' value='capex' />
                                <label htmlFor='3-y-capex' className='text-sm'>
                                    CapEx
                                </label>
                            </div>

                            <div className='flex items-center gap-2'>
                                <RadioGroupItem id='3-y-opex' value='opex'>
                                    OpEx
                                </RadioGroupItem>
                                <label htmlFor='3-y-opex' className='text-sm'>
                                    OpEx
                                </label>
                            </div>
                        </RadioGroup>
                    </div>

                    <Separator orientation='vertical' className='h-12' />

                    <div>
                        <p className='mb-4 text-xs text-newDesign-text-secondary'>
                            5 Year License
                        </p>
                        <RadioGroup
                            onValueChange={(value) => {
                                updateConfiguration('licenses', {
                                    ...licensesExpenseType,
                                    year5: value as ExpenseType,
                                });
                            }}
                            value={licensesExpenseType.year5}
                            className='flex gap-4'>
                            <div className='flex items-center gap-2'>
                                <RadioGroupItem id='5-y-capex' value='capex' />
                                <label htmlFor='5-y-capex' className='text-sm'>
                                    CapEx
                                </label>
                            </div>

                            <div className='flex items-center gap-2'>
                                <RadioGroupItem id='5-y-opex' value='opex'>
                                    OpEx
                                </RadioGroupItem>
                                <label htmlFor='5-y-opex' className='text-sm'>
                                    OpEx
                                </label>
                            </div>
                        </RadioGroup>
                    </div>
                </div>
            </div>
            <div className='flex w-full flex-col gap-4 rounded-md border border-newDesign-divider p-4'>
                <h3 className='font-semibold'>Model Defaults</h3>
                <div className='flex gap-2'>
                    <ModelRunTime />
                    <CostOfCapital />
                    <DepreciationTime />
                </div>
            </div>
        </section>
    );
};

export default Configuration;
