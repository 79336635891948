import { UseQueryResult } from '@tanstack/react-query';

import { ModelGroup } from '@bae/data-interface';

import { useListModelGroups } from '@/features/model-groups/api/list-model-groups.ts';

export const useListMistModels = (): UseQueryResult<ModelGroup[]> => {
    return useListModelGroups({
        queryConfig: {
            select: (data): ModelGroup[] => {
                return data.filter((group) => group.type === 'networkvendor');
            },
        },
    });
};
