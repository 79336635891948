/* eslint-disable camelcase */
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuTrigger,
} from '@radix-ui/react-dropdown-menu';
import React, { useState } from 'react';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { GrPowerReset } from 'react-icons/gr';
import { MdCheck, MdBlockFlipped } from 'react-icons/md';
import { RiDeleteBin2Line } from 'react-icons/ri';
import { toast } from 'react-toastify';

import TooltipWrapper from '@/components/ui/TooltipWrapper.tsx';
import { Button } from '@/components/ui/button.tsx';
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog.tsx';
import { DropdownMenuItem } from '@/components/ui/dropdown.tsx';
import { Separator } from '@/components/ui/separator.tsx';
import { useDeleteMembership } from '@/features/user-membership/api/delete-membership.ts';
import { useEditMembership } from '@/features/user-membership/api/edit-membership.ts';

const MembershipActions = ({
    email,
    organizationAdmin,
}: {
    email: string;
    organizationAdmin: boolean;
}) => {
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const deleteMembershipQuery = useDeleteMembership({
        mutationConfig: {
            onSuccess: () => {
                toast.success('User deleted successfully', { autoClose: 2000 });
            },
            onError: () => {
                toast.error('Failed to delete user');
            },
        },
    });

    const editMembershipQuery = useEditMembership({
        mutationConfig: {
            onSuccess: () => {
                toast.success('Updated Org. Admin successfully', {
                    autoClose: 2000,
                });
            },
            onError: () => {
                toast.error('Failed to update Org. Admin');
            },
        },
    });

    const handleDelete = () => {
        deleteMembershipQuery.mutate({ email });
    };

    const handleEditOrgAdmin = () => {
        editMembershipQuery.mutate({
            email,
            organization_manager: !organizationAdmin,
        });
    };

    const isPending =
        deleteMembershipQuery.isPending || editMembershipQuery.isPending;

    return (
        <>
            <DeleteDialog
                email={email}
                handleDelete={handleDelete}
                isModalOpen={openDeleteDialog}
                closeModal={() => setOpenDeleteDialog(false)}
            />
            <DropdownMenu>
                <DropdownMenuTrigger asChild>
                    {isPending ? (
                        <span className='p-1'>
                            <GrPowerReset className='size-5 animate-spin text-newDesign-primary' />
                        </span>
                    ) : (
                        <button className='rounded-md p-1  hover:bg-newDesign-text-hover'>
                            <TooltipWrapper text='Actions'>
                                <span>
                                    <BsThreeDotsVertical className='size-5 text-newDesign-primary' />
                                </span>
                            </TooltipWrapper>
                        </button>
                    )}
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                    <div className='w-50 grid grid-cols-1 rounded-md border bg-white p-2 shadow-lg'>
                        {organizationAdmin ? (
                            <DropdownMenuItem
                                onClick={handleEditOrgAdmin}
                                className='w-full justify-start text-sm  text-newDesign-text-primary'>
                                <MdBlockFlipped className='mr-2 size-5 text-newDesign-text-secondary' />
                                Remove Org. Admin
                            </DropdownMenuItem>
                        ) : (
                            <DropdownMenuItem
                                onClick={handleEditOrgAdmin}
                                className='w-full justify-start text-sm  text-newDesign-text-primary'>
                                <MdCheck className='mr-2 size-5 text-newDesign-primary' />
                                Set as Org. Admin
                            </DropdownMenuItem>
                        )}

                        <DropdownMenuItem
                            onClick={() => setOpenDeleteDialog(true)}
                            className='w-full justify-start text-sm  text-newDesign-text-primary'>
                            <RiDeleteBin2Line className='mr-2 size-5 text-newDesign-error' />
                            Delete
                        </DropdownMenuItem>
                    </div>
                </DropdownMenuContent>
            </DropdownMenu>
        </>
    );
};

const DeleteDialog = ({
    email,
    isModalOpen,
    closeModal,
    handleDelete,
}: {
    email: string;
    isModalOpen: boolean;
    closeModal: () => void;
    handleDelete: () => void;
}) => {
    const onDeleteClick = () => {
        handleDelete();
        closeModal();
    };

    return (
        <Dialog open={isModalOpen} onOpenChange={closeModal}>
            <DialogContent className='max-w-sm'>
                <DialogHeader>
                    <DialogTitle className='flex items-center'>
                        <RiDeleteBin2Line className='mr-2 size-8 text-newDesign-error-dark' />
                        Remove membership
                    </DialogTitle>
                </DialogHeader>
                <p>Are you sure you want to delete this membership?</p>
                <ul className='list-disc pl-5'>
                    <li className='[word-break:break-word]'>{email}</li>
                </ul>
                <Separator orientation='horizontal' />

                <DialogFooter className='items-center'>
                    <Button
                        onClick={closeModal}
                        variant='text'
                        className='text-newDesign-text-secondary'>
                        Cancel
                    </Button>
                    <Button onClick={onDeleteClick}>Delete</Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};

export default MembershipActions;
