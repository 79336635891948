import React from 'react';
import { useHistory } from 'react-router-dom';

import { RouteMap } from '@bae/routes';
import { getModel } from '@bae/store';

import { getFlatGroupModels } from '@/app/shared-pages/Scenario/utils.ts';
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@/components/ui/select.tsx';
import { useListMistModels } from '@/features/digital-twin/MistSettings/api/list-mist-models.ts';
import { useAppSelector } from '@/store';

const ScenarioSelector = ({ disabled }) => {
    const history = useHistory();
    const model = useAppSelector(getModel);

    const scenarioQuery = useListMistModels();

    const scenarios = getFlatGroupModels(scenarioQuery?.data);

    const handleChange = (value: string) => {
        history.push(`${RouteMap.scenarios.path}/${value}`);
    };

    return (
        <Select
            value={model.id}
            onValueChange={handleChange}
            disabled={
                disabled || scenarioQuery.isFetching || scenarioQuery.isLoading
            }>
            <SelectTrigger className='h-6 w-fit max-w-[230px] border-newDesign-secondary text-start text-sm font-semibold text-newDesign-secondary focus:ring-newDesign-secondary'>
                <SelectValue defaultValue={model.id} />
            </SelectTrigger>
            <SelectContent>
                <SelectGroup>
                    {/*{digitalTwinQuery.data && (*/}
                    {/*    <SelectItem*/}
                    {/*        className='cursor-pointer text-sm data-[highlighted]:bg-white data-[highlighted]:ring-newDesign-secondary'*/}
                    {/*        key={digitalTwinQuery.data.id}*/}
                    {/*        value={digitalTwinQuery.data.id}>*/}
                    {/*        {digitalTwinQuery.data.name}*/}
                    {/*    </SelectItem>*/}
                    {/*)}*/}
                    {scenarios.map((scenario) => (
                        <SelectItem
                            className='cursor-pointer text-sm data-[highlighted]:bg-white data-[highlighted]:ring-newDesign-secondary'
                            key={scenario.id}
                            value={scenario.id}>
                            {scenario.name}
                        </SelectItem>
                    ))}
                </SelectGroup>
            </SelectContent>
        </Select>
    );
};

export default ScenarioSelector;
