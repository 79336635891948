import { z } from 'zod';

import React, { useEffect, FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { PiWarningThin } from 'react-icons/pi';
import { toast } from 'react-toastify';

import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert.tsx';
import { Button } from '@/components/ui/button.tsx';
import {
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog.tsx';
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '@/components/ui/form.tsx';
import { Input } from '@/components/ui/input.tsx';
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@/components/ui/select.tsx';
import { Skeleton } from '@/components/ui/skeleton.tsx';
import { useGetMistDevices } from '@/features/digital-twin/MistSettings/api/get-mist-devices.ts';
import SwitchCombobox from '@/features/digital-twin/MistSettings/components/Switches/components/SwitchCombobox.tsx';
import useMistStore from '@/features/digital-twin/MistSettings/store';

import type { SwitchInput } from '../../../types.ts';
import DelayedDeploymentTable from '../../shared/DelayedDeployment/DelayedDeploymentTable.tsx';
import { zodResolver } from '@hookform/resolvers/zod';

const formSchema = z.object({
    name: z
        .string()
        .min(2, 'Name must contain at least 2 characters')
        .max(50, 'Name must be at most 50 characters long'),
    site: z.string().min(1, 'You must select a site'),
    sku: z.string().min(1, 'You must select a model'),
});

interface Props {
    isOpen: boolean;
    closeModal: () => void;
    switch?: SwitchInput;
}

const defaultValues = {
    name: '',
    site: '',
    sku: '',
};

const defaultDelayedDeployments = [
    {
        delay: 0,
        quantity: 1,
    },
];

const SwitchDialog: FC<Props> = ({
    isOpen,
    closeModal,
    switch: switch_device,
}) => {
    const addSwitch = useMistStore((state) => state.addSwitch);
    const updateSwitch = useMistStore((state) => state.updateSwitch);

    const sites = useMistStore((state) => state.data.sites);
    const switches = useMistStore((state) => state.data.switches);
    const optionalLicenses = useMistStore(
        (state) => state.data.optionalLicenses,
    );

    const switchLicensing = optionalLicenses.filter(
        ({ vendor }) => vendor === 'juniper/mist',
    )[0].switches;

    const [delayedDeployments, setDelayedDeployments] = useState(
        defaultDelayedDeployments,
    );

    const emptySites = sites.length === 0;

    useEffect(() => {
        if (switch_device?.qtyTimeSeries) {
            setDelayedDeployments(switch_device.qtyTimeSeries);
        }
    }, [switch_device, isOpen]);

    const { data: switchModels, isFetching: isFetchingSwitchModels } =
        useGetMistDevices({ deviceType: 'switch' });

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        disabled: emptySites,
        defaultValues: switch_device
            ? {
                  name: switch_device.name,
                  site: switch_device.site,
                  sku: switch_device.sku,
              }
            : defaultValues,
    });

    function onSubmit(values: z.infer<typeof formSchema>) {
        if (switch_device) {
            const isDuplicate = switches.find(
                ({ name }) =>
                    name.toLowerCase() === values.name.toLowerCase() &&
                    values.name.toLowerCase() !==
                        switch_device.name.toLowerCase(),
            );

            if (isDuplicate) {
                toast.error('Duplicate switches are not allowed.');
                return;
            }

            updateSwitch(switch_device, {
                ...values,
                vendor: 'juniper/mist',
                licenses: switchLicensing.licenses,
                licensing: {
                    maintenance: switchLicensing.maintenance,
                    term: switchLicensing.term,
                    total_number: switchLicensing.total_number,
                    expirationTime: null,
                },
                expense: 'capex',
                group: [],
                qtyTimeSeries: delayedDeployments,
                status:
                    switch_device.status === 'original'
                        ? 'modified'
                        : switch_device.status,
            } as SwitchInput);
        } else {
            const isDuplicate = switches.find(
                ({ name }) => name.toLowerCase() === values.name.toLowerCase(),
            );

            if (isDuplicate) {
                toast.error('Duplicate switches are not allowed.');
                return;
            }

            addSwitch({
                ...values,
                vendor: 'juniper/mist',
                licenses: switchLicensing.licenses,
                licensing: {
                    maintenance: switchLicensing.maintenance,
                    term: switchLicensing.term,
                    total_number: switchLicensing.total_number,
                    expirationTime: null,
                },
                group: [],
                expense: 'capex',
                qtyTimeSeries: delayedDeployments,
                status: 'added',
            } as SwitchInput);
        }

        closeModal();
    }

    useEffect(() => {
        if (!isOpen) {
            form.reset();
            setDelayedDeployments(
                switch_device
                    ? switch_device.qtyTimeSeries
                    : defaultDelayedDeployments,
            );
        }
    }, [switch_device, form, isOpen]);

    const getSwitchModelDescription = (sku: string) => {
        return switchModels?.find((s) => s.sku === sku)?.description;
    };

    const selectedModel = form.watch('sku');

    return (
        <DialogContent className='max-h-dvh max-w-[500px] overflow-y-auto sm:max-w-[1000px]'>
            <DialogHeader>
                <DialogTitle>New Switch</DialogTitle>
                <DialogDescription>
                    Fill in the fields below to add a new switch.
                </DialogDescription>
            </DialogHeader>
            <div className='relative grid grid-cols-1 gap-8 lg:grid-cols-2'>
                {emptySites && (
                    <div className='absolute bottom-[-8px] left-[-8px] right-[-8px] top-[-8px] z-[60] flex items-center justify-center rounded-lg bg-newDesign-divider bg-opacity-75 p-20'>
                        <Alert>
                            <PiWarningThin className='size-4' />

                            <AlertTitle>Sites missing!</AlertTitle>
                            <AlertDescription>
                                Create a Site first to enable adding Switches
                            </AlertDescription>
                        </Alert>
                    </div>
                )}

                <Form {...form}>
                    <form
                        onSubmit={form.handleSubmit(onSubmit)}
                        className='grid grid-cols-1 gap-4 md:grid-cols-2'>
                        <FormField
                            control={form.control}
                            name='name'
                            render={({ field }) => (
                                <FormItem className='col-span-1 md:col-span-2'>
                                    <FormLabel>Switch</FormLabel>
                                    <FormControl>
                                        <Input
                                            placeholder='Enter the Switch name...'
                                            {...field}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name='site'
                            render={({ field }) => {
                                const { onChange, ...rest } = field;

                                return (
                                    <FormItem>
                                        <FormLabel>Site</FormLabel>
                                        <FormControl>
                                            <Select
                                                onValueChange={onChange}
                                                {...rest}>
                                                <SelectTrigger>
                                                    <SelectValue placeholder='Select site...' />
                                                </SelectTrigger>
                                                <SelectContent>
                                                    <SelectGroup>
                                                        {sites.map(
                                                            ({ site }) => (
                                                                <SelectItem
                                                                    key={site}
                                                                    value={
                                                                        site
                                                                    }>
                                                                    {site}
                                                                </SelectItem>
                                                            ),
                                                        )}
                                                    </SelectGroup>
                                                </SelectContent>
                                            </Select>
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                );
                            }}
                        />

                        <FormField
                            control={form.control}
                            name='sku'
                            render={({
                                field: { onChange, value, disabled },
                            }) => {
                                return (
                                    <FormItem>
                                        <FormLabel>Model</FormLabel>
                                        <FormControl>
                                            <SwitchCombobox
                                                value={value}
                                                onChange={onChange}
                                                switchModels={switchModels}
                                                disabled={
                                                    disabled ||
                                                    isFetchingSwitchModels
                                                }
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                );
                            }}
                        />

                        {selectedModel.length > 0 && (
                            <div className='col-span-1 my-1 w-full md:col-span-2'>
                                <p className='mb-0.5 text-xs text-newDesign-text-secondary'>
                                    Model description:
                                </p>
                                <p className='text-xs italic text-newDesign-text-secondary'>
                                    {getSwitchModelDescription(selectedModel)}
                                </p>
                            </div>
                        )}

                        {/*<FormField*/}
                        {/*    control={form.control}*/}
                        {/*    name='avgTrafficPerSwitch'*/}
                        {/*    render={({ field }) => {*/}
                        {/*        const { onChange, ...rest } = field;*/}

                        {/*        return (*/}
                        {/*            <FormItem>*/}
                        {/*                <FormLabel>*/}
                        {/*                    Average Traffic per Switch (Mbps)*/}
                        {/*                </FormLabel>*/}
                        {/*                <FormControl>*/}
                        {/*                    <CurrencyInput*/}
                        {/*                        className={cn(*/}
                        {/*                            'h-10 w-full rounded-md border px-3 py-2 focus-visible:outline-none',*/}
                        {/*                            'focus-visible:ring-2 focus-visible:ring-neutral-950 focus-visible:ring-offset-2',*/}
                        {/*                        )}*/}
                        {/*                        intlConfig={{ locale: 'en-US' }}*/}
                        {/*                        decimalsLimit={8}*/}
                        {/*                        allowNegativeValue={false}*/}
                        {/*                        defaultValue={0}*/}
                        {/*                        onValueChange={(value) => {*/}
                        {/*                            onChange(value || '0');*/}
                        {/*                        }}*/}
                        {/*                        {...rest}*/}
                        {/*                    />*/}
                        {/*                </FormControl>*/}
                        {/*                <FormMessage />*/}
                        {/*            </FormItem>*/}
                        {/*        );*/}
                        {/*    }}*/}
                        {/*/>*/}

                        {/*<FormField*/}
                        {/*    control={form.control}*/}
                        {/*    name='trafficGrowth'*/}
                        {/*    render={({ field }) => {*/}
                        {/*        const { onChange, ...rest } = field;*/}

                        {/*        return (*/}
                        {/*            <FormItem>*/}
                        {/*                <FormLabel>*/}
                        {/*                    Traffic Growth Rate*/}
                        {/*                </FormLabel>*/}
                        {/*                <FormControl>*/}
                        {/*                    <CurrencyInput*/}
                        {/*                        className={cn(*/}
                        {/*                            'h-10 w-full rounded-md border px-3 py-2 focus-visible:outline-none',*/}
                        {/*                            'focus-visible:ring-2 focus-visible:ring-neutral-950 focus-visible:ring-offset-2',*/}
                        {/*                        )}*/}
                        {/*                        intlConfig={{ locale: 'en-US' }}*/}
                        {/*                        suffix=' %'*/}
                        {/*                        decimalsLimit={4}*/}
                        {/*                        defaultValue={0}*/}
                        {/*                        onValueChange={(value) => {*/}
                        {/*                            if (*/}
                        {/*                                parseFloat(value) > 100*/}
                        {/*                            ) {*/}
                        {/*                                return onChange('100');*/}
                        {/*                            }*/}

                        {/*                            onChange(value || '0');*/}
                        {/*                        }}*/}
                        {/*                        {...rest}*/}
                        {/*                    />*/}
                        {/*                </FormControl>*/}
                        {/*                <FormMessage />*/}
                        {/*            </FormItem>*/}
                        {/*        );*/}
                        {/*    }}*/}
                        {/*/>*/}
                    </form>
                </Form>

                {emptySites ? (
                    <div className='flex w-full flex-col gap-4'>
                        <Skeleton className='h-20 w-full' />
                        <Skeleton className='size-full' />
                    </div>
                ) : (
                    <DelayedDeploymentTable
                        title='Delayed Deployment'
                        data={delayedDeployments}
                        onChange={setDelayedDeployments}
                        className='w-full'
                    />
                )}
            </div>
            <DialogFooter className='items-end gap-4 lg:gap-0'>
                <Button onClick={closeModal} type='button' variant='outline'>
                    Cancel
                </Button>
                <Button
                    disabled={emptySites}
                    onClick={form.handleSubmit(onSubmit)}
                    type='submit'>
                    Add
                </Button>
            </DialogFooter>
        </DialogContent>
    );
};

export default SwitchDialog;
