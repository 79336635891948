/* eslint-disable react-hooks/rules-of-hooks */
import { ColumnDef } from '@tanstack/react-table';
import React, { useState } from 'react';
import { CiCircleInfo } from 'react-icons/ci';
import { MdEdit } from 'react-icons/md';
import { RiDeleteBin2Line } from 'react-icons/ri';

import { divide, formatPercent } from '@bae/utils';

import TooltipWrapper from '@/components/ui/TooltipWrapper.tsx';
import { Button } from '@/components/ui/button.tsx';
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from '@/components/ui/dialog.tsx';
import { Separator } from '@/components/ui/separator.tsx';
import AccessPointDialog from '@/features/digital-twin/MistSettings/components/AccessPoints/components/AccessPointDialog.tsx';
import useMistStore from '@/features/digital-twin/MistSettings/store';
import { cn } from '@/lib/utils.ts';

import {
    AccessPointInput,
    type IQuantityTimeSeriesEntry,
} from '../../types.ts';
import DelayedDeploymentDialog from '../shared/DelayedDeployment/DelayedDeploymentDialog.tsx';
import StatusBadge from '../shared/StatusBadge.tsx';

export const columnConfig: ColumnDef<AccessPointInput>[] = [
    {
        accessorKey: 'status',
        header: () => <p className='text-newDesign-text-secondary'>Status</p>,
        size: 120,
        cell: (ctx) => {
            const status: 'modified' | 'added' | 'original' =
                ctx.row.getValue('status');

            const mapStatus = {
                modified: 'Modified',
                added: 'Added',
                original: 'Original',
            } as const;

            return (
                <p className='flex w-[80px] items-center gap-2'>
                    <StatusBadge status={ctx.row.getValue('status')} />
                    {mapStatus[status]}
                </p>
            );
        },
    },
    {
        id: 'site',
        accessorKey: 'site',
        size: 200,
        header: () => <p className='text-newDesign-text-secondary'>Site</p>,
        cell: (ctx) => {
            return (
                <TooltipWrapper text={ctx.row.getValue('site')}>
                    <p className='max-w-[150px] overflow-hidden text-ellipsis whitespace-nowrap'>
                        {ctx.row.getValue('site')}
                    </p>
                </TooltipWrapper>
            );
        },
    },
    {
        id: 'name',
        accessorKey: 'name',
        size: 200,
        header: () => <p className='text-newDesign-text-secondary'>Name</p>,
        cell: (ctx) => {
            return (
                <TooltipWrapper text={ctx.row.getValue('name')}>
                    <p className='max-w-[150px] overflow-hidden text-ellipsis whitespace-nowrap'>
                        {ctx.row.getValue('name')}
                    </p>
                </TooltipWrapper>
            );
        },
    },
    {
        accessorKey: 'sku',
        size: 100,
        header: () => <p className='text-newDesign-text-secondary'>Model</p>,
        cell: (ctx) => {
            return (
                <TooltipWrapper text={ctx.row.getValue('sku')}>
                    <p className='w-[80px] overflow-hidden text-ellipsis whitespace-nowrap'>
                        {ctx.row.getValue('sku')}
                    </p>
                </TooltipWrapper>
            );
        },
    },
    {
        accessorKey: 'currentQty',
        header: () => (
            <TooltipWrapper text='Quantity deployed at the delay 0, which is the present moment.'>
                <span className='text-center text-newDesign-text-secondary'>
                    <p className='whitespace-nowrap'>Current</p>
                    <p className='whitespace-nowrap'>
                        Quantity
                        <CiCircleInfo className='ml-1 inline-block size-3.5 stroke-1 text-newDesign-secondary' />
                    </p>
                </span>
            </TooltipWrapper>
        ),
        cell: (ctx) => {
            const qtyTimeSeries = ctx.row.original.qtyTimeSeries;

            const delayZeroTimeSeries = qtyTimeSeries.find(
                (entry) => entry.delay === 0,
            );

            return delayZeroTimeSeries ? (
                <p className='mr-4 flex items-center justify-center'>
                    {delayZeroTimeSeries.quantity}
                </p>
            ) : (
                <TooltipWrapper text='See the delayed deployments.'>
                    <p className='mr-4 flex items-center justify-center'>
                        <CiCircleInfo className='size-4' />
                    </p>
                </TooltipWrapper>
            );
        },
    },
    {
        id: 'delayedDeployments',
        header: () => (
            <span className='text-center text-newDesign-text-secondary'>
                <p className='whitespace-nowrap'> Delayed </p>
                <p>Deployments</p>
            </span>
        ),
        cell: (ctx) => {
            const updateAccessPoint = useMistStore(
                (state) => state.updateAccessPoint,
            );

            const accessPoint = ctx.row.original;
            const qtyTimeSeries = ctx.row.original.qtyTimeSeries;

            const handleSave = (data: IQuantityTimeSeriesEntry[]) => {
                const updatedAccessPoint: AccessPointInput = {
                    ...accessPoint,
                    qtyTimeSeries: data,
                };

                updateAccessPoint(accessPoint, updatedAccessPoint);
            };

            return (
                <div className='group mr-4 flex items-center justify-center rounded-sm p-1'>
                    <DelayedDeploymentDialog
                        onSave={handleSave}
                        data={qtyTimeSeries}
                        disabled={false}
                    />
                </div>
            );
        },
    },
    // {
    //     accessorKey: 'trafficMbps',
    //     header: () => (
    //         <span className='text-center text-newDesign-text-secondary'>
    //             <p className='whitespace-nowrap'>Site Traffic</p>
    //             <p>(Mbps)</p>
    //         </span>
    //     ),
    //     cell: (ctx) => {
    //         return (
    //             <p className='mr-4 flex items-center justify-center'>
    //                 {ctx.row.getValue('trafficMbps')}
    //             </p>
    //         );
    //     },
    // },
    // {
    //     accessorKey: 'clientsPerSite',
    //     header: () => (
    //         <span className='text-center text-newDesign-text-secondary'>
    //             <p className='whitespace-nowrap'>Wireless Clients</p>
    //             <p>per Site</p>
    //         </span>
    //     ),
    //     cell: (ctx) => {
    //         return (
    //             <p className='mr-4 flex items-center justify-center'>
    //                 {ctx.row.getValue('clientsPerSite')}
    //             </p>
    //         );
    //     },
    // },
    {
        accessorKey: 'avgTrafficPerAP',
        header: () => (
            <span className='text-center text-newDesign-text-secondary'>
                <p className='whitespace-nowrap'>Average Traffic</p>
                <p>per AP (Mbps)</p>
            </span>
        ),
        cell: (ctx) => {
            return (
                <p className='mr-4 flex items-center justify-center'>
                    {ctx.row.getValue('avgTrafficPerAP')}
                </p>
            );
        },
    },
    {
        accessorKey: 'avgClientsPerAP',
        header: () => (
            <span className='text-center text-newDesign-text-secondary'>
                <p className='whitespace-nowrap'>Average Wireless</p>
                <p>Clients per AP (Mbps)</p>
            </span>
        ),
        cell: (ctx) => {
            return (
                <p className='mr-4 flex items-center justify-center'>
                    {ctx.row.getValue('avgClientsPerAP')}
                </p>
            );
        },
    },
    {
        accessorKey: 'trafficGrowth',
        header: () => (
            <span className='text-center text-newDesign-text-secondary'>
                <p className='whitespace-nowrap'>Traffic Growth</p>
                <p>Rate</p>
            </span>
        ),
        cell: (ctx) => {
            const numberAsPercent = divide(
                Number(ctx.row.getValue('trafficGrowth')),
                100,
            );

            return (
                <p className='mr-4 flex items-center justify-center'>
                    {formatPercent(numberAsPercent, 2)}
                </p>
            );
        },
    },
    {
        accessorKey: 'clientGrowth',
        header: () => (
            <span className='text-center text-newDesign-text-secondary'>
                <p className='whitespace-nowrap'>Wireless Client</p>
                <p>Growth Rate</p>
            </span>
        ),
        cell: (ctx) => {
            const numberAsPercent = divide(
                Number(ctx.row.getValue('clientGrowth')),
                100,
            );

            return (
                <p className='mr-4 flex items-center justify-center'>
                    {formatPercent(numberAsPercent, 2)}
                </p>
            );
        },
    },
    {
        id: 'actions',
        accessorKey: 'actions',
        header: () => (
            <p className='text-center text-newDesign-text-secondary'>Actions</p>
        ),
        cell: (ctx) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const [isEditing, setIsEditing] = useState(false);
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const [isDeleting, setIsDeleting] = useState(false);

            // eslint-disable-next-line react-hooks/rules-of-hooks
            const deleteAccessPoint = useMistStore(
                (state) => state.deleteAccessPoint,
            );

            const handleDeleteAccessPoint = () => {
                deleteAccessPoint(ctx.row.original);
                setIsDeleting(false);
            };

            return (
                <div className='mr-4 flex items-center justify-center gap-2'>
                    <Dialog open={isEditing} onOpenChange={setIsEditing}>
                        <DialogTrigger asChild>
                            <Button
                                size='icon'
                                variant='text'
                                className='group size-6 bg-transparent p-1 disabled:cursor-default disabled:bg-opacity-10'>
                                <MdEdit
                                    className={cn(
                                        'size-4 group-hover:text-white',
                                    )}
                                />
                            </Button>
                        </DialogTrigger>
                        <AccessPointDialog
                            isOpen={isEditing}
                            closeModal={() => setIsEditing(false)}
                            accessPoint={ctx.row.original}
                        />
                    </Dialog>

                    <Dialog open={isDeleting} onOpenChange={setIsDeleting}>
                        <DialogTrigger>
                            <Button
                                size='icon'
                                variant='text'
                                className='group size-6 bg-transparent p-1 hover:bg-newDesign-error-light active:bg-newDesign-error disabled:cursor-default disabled:bg-opacity-10'>
                                <RiDeleteBin2Line
                                    className={cn(
                                        'size-4 text-newDesign-error group-hover:text-white',
                                    )}
                                />
                            </Button>
                        </DialogTrigger>
                        <DialogContent className='max-w-sm'>
                            <DialogHeader>
                                <DialogTitle className='flex items-center'>
                                    <RiDeleteBin2Line className='mr-2 size-8 text-newDesign-error-dark' />
                                    Delete Access Point
                                </DialogTitle>
                                <DialogDescription>
                                    Are you sure you want to delete this Access
                                    Point?
                                </DialogDescription>
                            </DialogHeader>

                            <ul className='list-disc pl-5'>
                                <li className='[word-break:break-word]'>
                                    {ctx.row.original.name}
                                </li>
                            </ul>

                            <Separator orientation='horizontal' />

                            <DialogFooter className='items-center'>
                                <Button onClick={handleDeleteAccessPoint}>
                                    Delete
                                </Button>
                            </DialogFooter>
                        </DialogContent>
                    </Dialog>
                </div>
            );
        },
        size: 120,
        meta: { sticky: 'right' },
    },
];
