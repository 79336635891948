import lodash from 'lodash';

import { useEffect, useState } from 'react';
import { TbClockCog } from 'react-icons/tb';

import { Button } from '@/components/ui/button.tsx';
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogDescription,
    DialogTrigger,
} from '@/components/ui/dialog.tsx';
import { cn } from '@/lib/utils.ts';

import type { IQuantityTimeSeriesEntry } from '../../../types.ts';
import DelayedDeploymentTable from './DelayedDeploymentTable.tsx';

const DelayedDeploymentDialog = ({
    onSave,
    data,
    disabled,
}: {
    onSave?: (data: IQuantityTimeSeriesEntry[]) => void;
    data: IQuantityTimeSeriesEntry[];
    disabled: boolean;
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const [delayedDeployments, setDelayedDeployments] =
        useState<IQuantityTimeSeriesEntry[]>(data);

    useEffect(() => {
        if (!isOpen) setDelayedDeployments(data);
    }, [data, isOpen]);

    const hasChanges = !lodash.isEqual(delayedDeployments, data);

    const handleSave = () => {
        if (onSave) onSave(delayedDeployments);
        setIsOpen(false);
    };

    return (
        <Dialog onOpenChange={setIsOpen} open={isOpen}>
            <DialogTrigger asChild>
                <Button
                    disabled={disabled}
                    size='icon'
                    variant='text'
                    className='size-7 bg-transparent p-1 disabled:cursor-default disabled:bg-opacity-10'>
                    <TbClockCog className={cn('size-5 ')} />
                </Button>
            </DialogTrigger>
            <DialogContent className='w-fit md:w-[550px]'>
                <DialogHeader>
                    <DialogTitle>Delayed Deployment</DialogTitle>
                    <DialogDescription>
                        {/* TODO: add description */}
                        Describe how the quantity grows, starting from the
                        present moment (delay = 0)
                    </DialogDescription>
                </DialogHeader>
                <DelayedDeploymentTable
                    onChange={setDelayedDeployments}
                    data={delayedDeployments}
                />
                <DialogFooter>
                    <Button
                        disabled={!hasChanges}
                        variant='text'
                        type='button'
                        onClick={() => setDelayedDeployments(data)}>
                        Restore
                    </Button>
                    <Button
                        onClick={handleSave}
                        disabled={!hasChanges}
                        type='submit'>
                        Confirm
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};

export default DelayedDeploymentDialog;
