import { isEqual } from 'lodash';
import { createSelector } from 'reselect';
import { create } from 'zustand';

import type {
    AccessPointInput,
    FteInput,
    Licenses,
    MistConfigurationInput,
    MistDataInput,
    SiteInput,
    SwitchInput,
} from '../types.ts';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

const initialData: MistDataInput = {
    accessPoints: [],
    switches: [],
    wanEdge: [],
    // mistEdge: [],
    sites: [],
    ftes: [],
    optionalLicenses: [
        {
            vendor: 'juniper/mist',
            aps: {
                total_number: 0,
                term: 0,
                licenses: [],
            },
            switches: {
                maintenance: 'COR',
                total_number: 0,
                term: 0,
                licenses: [],
            },
        },
    ],
    configuration: {
        licenses: {
            year1: 'opex',
            year3: 'opex',
            year5: 'opex',
        },
        capital_cost: '5',
        periods: '60',
        depreciation_time: '36',
    },
    pricing: null,
};

interface IMistStore {
    originalData: MistDataInput;

    data: MistDataInput;

    updateAccessPoint: (
        prevData: AccessPointInput,
        nextData: AccessPointInput,
    ) => void;
    addAccessPoint: (newAccessPoint: AccessPointInput) => void;
    deleteAccessPoint: (prevData: AccessPointInput) => void;

    updateSwitch: (prevData: SwitchInput, nextData: SwitchInput) => void;
    addSwitch: (newSwitch: SwitchInput) => void;
    deleteSwitch: (prevData: SwitchInput) => void;

    updateSite: (prevData: SiteInput, nextData: SiteInput) => void;
    addSite: (newSite: SiteInput) => void;
    deleteSite: (prevData: SiteInput) => void;

    updateFte: (prevData: FteInput, nextData: FteInput) => void;
    addFte: (newFte: FteInput) => void;
    deleteFte: (prevData: FteInput) => void;

    updateOptionalLicense: (
        vendor: string,
        deviceType: 'aps' | 'switches',
        updateFn: (
            current:
                | {
                      total_number: number;
                      term: number;
                      licenses: Licenses[];
                  }
                | {
                      maintenance: string | null;
                      total_number: number;
                      term: number;
                      licenses: Licenses[];
                  },
        ) => typeof current,
    ) => void;

    updateConfiguration: <K extends keyof MistConfigurationInput>(
        key: K,
        value: MistConfigurationInput[K],
    ) => void;

    resetData: () => void;
    setData: (data: MistDataInput) => void;

    getCurrentData: () => MistDataInput;
}

const useMistStore = create<IMistStore>()(
    devtools(
        immer((set, get) => ({
            // stores the data from server, used in case of reset
            originalData: initialData,
            // changes will be applied here
            data: initialData,

            setData: (data) => set(() => ({ data, originalData: data })),

            // Update methods
            updateAccessPoint: (prevData, nextData) =>
                set((state) => {
                    const index = state.data.accessPoints.findIndex((ap) =>
                        isEqual(ap, prevData),
                    );
                    if (index !== -1) {
                        state.data.accessPoints[index] = nextData;
                    }
                }),

            addAccessPoint: (newAccessPoint: AccessPointInput) =>
                set((state) => {
                    state.data.accessPoints.push(newAccessPoint);
                }),

            deleteAccessPoint: (prevData) =>
                set((state) => {
                    const index = state.data.accessPoints.findIndex((ap) =>
                        isEqual(ap, prevData),
                    );

                    state.data.accessPoints.splice(index, 1);
                }),

            updateSwitch: (prevData, nextData) =>
                set((state) => {
                    const index = state.data.switches.findIndex((ap) =>
                        isEqual(ap, prevData),
                    );
                    if (index !== -1) {
                        state.data.switches[index] = nextData;
                    }
                }),

            addSwitch: (newSwitch) =>
                set((state) => {
                    state.data.switches.push(newSwitch);
                }),

            deleteSwitch: (prevData) =>
                set((state) => {
                    const index = state.data.switches.findIndex((ap) =>
                        isEqual(ap, prevData),
                    );

                    state.data.switches.splice(index, 1);
                }),

            updateSite: (prevData, nextData) =>
                set((state) => {
                    const index = state.data.sites.findIndex((site) =>
                        isEqual(site, prevData),
                    );
                    if (index !== -1) {
                        state.data.sites[index] = nextData;
                    }
                }),

            addSite: (newSite: SiteInput) =>
                set((state) => {
                    state.data.sites.push(newSite);
                }),

            deleteSite: (prevData) =>
                set((state) => {
                    const index = state.data.sites.findIndex((site) =>
                        isEqual(site, prevData),
                    );

                    state.data.sites.splice(index, 1);
                }),

            updateFte: (prevData, nextData) =>
                set((state) => {
                    const index = state.data.ftes.findIndex((fte) =>
                        isEqual(fte, prevData),
                    );
                    if (index !== -1) {
                        state.data.ftes[index] = nextData;
                    }
                }),

            addFte: (newFte: FteInput) =>
                set((state) => {
                    state.data.ftes.push(newFte);
                }),

            deleteFte: (prevData) =>
                set((state) => {
                    const index = state.data.ftes.findIndex((site) =>
                        isEqual(site, prevData),
                    );

                    state.data.ftes.splice(index, 1);
                }),

            // In your Zustand store definition
            updateOptionalLicense: (vendor, deviceType, updateFn) => {
                set((state) => ({
                    data: {
                        ...state.data,
                        optionalLicenses: state.data.optionalLicenses.map(
                            (entry) => {
                                if (entry.vendor !== vendor) return entry;

                                const currentDevice = entry[deviceType];
                                const updatedDevice = updateFn(currentDevice);

                                return {
                                    ...entry,
                                    [deviceType]: {
                                        ...currentDevice,
                                        ...updatedDevice,
                                    },
                                };
                            },
                        ),
                    },
                }));
            },

            updateConfiguration: (key, value) =>
                set((state) => {
                    state.data.configuration[key] = value;
                }),

            resetData: () =>
                set((state) => ({
                    data: state.originalData,
                })),

            getCurrentData: () => {
                return get().data;
            },
        })),
        {
            name: 'MistStore',
        },
    ),
);

const selectAccessPoints = (state: IMistStore) => state.data.accessPoints;
const selectSwitches = (state: IMistStore) => state.data.switches;

const getSitesWithDeployedDevices = createSelector(
    selectAccessPoints,
    selectSwitches,
    (accessPoints, switches): string[] => {
        const accessPointsSites = accessPoints.map(
            (accessPoint) => accessPoint.site,
        );
        const switchesSites = switches.map(
            (switch_device) => switch_device.site,
        );

        return [...new Set([...accessPointsSites, ...switchesSites])];
    },
);

export const getIsSiteWithDevice = (site: string) => {
    const sitesWithDevice = getSitesWithDeployedDevices(
        useMistStore.getState(),
    );

    return sitesWithDevice.includes(site);
};

export default useMistStore;
