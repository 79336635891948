import { isEqual } from 'lodash';

import { GlobeIcon } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { CiFloppyDisk } from 'react-icons/ci';
import { PiGearLight } from 'react-icons/pi';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { RouteMap } from '@bae/routes';

import { Badge } from '@/components/ui/badge.tsx';
import { Button } from '@/components/ui/button.tsx';
import { Separator } from '@/components/ui/separator.tsx';
import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
} from '@/components/ui/tabs.tsx';
import { usePostMistModel } from '@/features/digital-twin/MistSettings/api/post-mist-model.ts';
import Licenses from '@/features/digital-twin/MistSettings/components/Licenses/Licenses.tsx';
import { useGetModel } from '@/features/models/api/get-model.ts';

import { useGetMistInput } from './api/get-mist-input.ts';
import AccessPointsTable from './components/AccessPoints/AccessPointsTable.tsx';
import AddNewDialogContainer from './components/AddNewDialogContainer/AddNewDialogContainer.tsx';
import Configuration from './components/Configuration/Configuration.tsx';
import FteTable from './components/FTE/FteTable.tsx';
import SiteTable from './components/Sites/SitesTable.tsx';
import SwitchesTable from './components/Switches/SwitchesTable.tsx';
import useMistStore from './store';

type TabsType = 'accessPoints' | 'fte' | 'sites' | 'licenses' | 'configuration';

const MistSettings = () => {
    const history = useHistory();

    const setData = useMistStore((state) => state.setData);
    const resetData = useMistStore((state) => state.resetData);

    const mistData = useMistStore((state) => state.data);
    const originalData = useMistStore((state) => state.originalData);

    const [selectedTab, setSelectedTab] = useState<TabsType>('sites');

    const modelIdFromParams = useParams<{ modelId: string }>().modelId;

    const {
        isFetching: isFetchingModel,
        data: modelData,
        isSuccess: isModelSuccess,
    } = useGetModel({
        modelId: modelIdFromParams,
    });

    const { data, isFetching, isSuccess } = useGetMistInput({
        groupId: modelData.groupId,
        enabled: isModelSuccess,
    });

    const postModelQuery = usePostMistModel({
        mutationConfig: {
            onSuccess: (data) => {
                toast.success('Scenario saved successfully', {
                    autoClose: 2000,
                });
                history.push(
                    `${RouteMap.scenarios.path}/${data.model_id}/settings`,
                );
            },
            onError: (error) => {
                toast.error(String(error) || 'Failed to save scenario');
            },
        },
    });

    const handleSaveModel = () => {
        postModelQuery.mutate({
            replace_group_id: modelData.groupId,
            mist_input: mistData,
        });
    };

    useEffect(() => {
        if (isSuccess) {
            setData(data);
        }
    }, [data, isSuccess, setData]);

    const isDirty = !isEqual(mistData, originalData);

    if (isFetching || isFetchingModel) return null;

    return (
        <div className='mx-auto w-full max-w-screen-5xl'>
            <div className='mb-4 mt-2 flex flex-wrap gap-2'>
                <div className='flex gap-2 whitespace-nowrap'>
                    <GlobeIcon className='size-6 text-newDesign-primary' />
                    <h2 className='text-lg font-semibold'>Settings</h2>
                </div>
                {isDirty && (
                    <Badge variant='warning-outline'>
                        This model has unsaved changes. Save before leaving the
                        page.
                    </Badge>
                )}
            </div>
            <Tabs
                value={selectedTab}
                onValueChange={(value) => setSelectedTab(value as TabsType)}>
                <TabsList className='w-full justify-start gap-2 bg-white'>
                    <TabsTrigger
                        className='bg-newDesign-background data-[state=active]:text-newDesign-primary data-[state=active]:ring-1 data-[state=active]:ring-newDesign-primary'
                        value='sites'>
                        Sites
                    </TabsTrigger>

                    <TabsTrigger
                        className='bg-newDesign-background data-[state=active]:text-newDesign-primary data-[state=active]:ring-1 data-[state=active]:ring-newDesign-primary'
                        value='accessPoints'>
                        Access Points
                    </TabsTrigger>

                    <TabsTrigger
                        className='bg-newDesign-background data-[state=active]:text-newDesign-primary data-[state=active]:ring-1 data-[state=active]:ring-newDesign-primary'
                        value='switches'>
                        Switches
                    </TabsTrigger>

                    <TabsTrigger
                        className='bg-newDesign-background data-[state=active]:text-newDesign-primary data-[state=active]:ring-1 data-[state=active]:ring-newDesign-primary'
                        value='fte'>
                        FTE
                    </TabsTrigger>

                    <TabsTrigger
                        className='bg-newDesign-background data-[state=active]:text-newDesign-primary data-[state=active]:ring-1 data-[state=active]:ring-newDesign-primary'
                        value='licenses'>
                        Licenses
                    </TabsTrigger>

                    <TabsTrigger
                        className='ring-1 ring-newDesign-divider data-[state=active]:text-newDesign-primary data-[state=active]:ring-newDesign-primary'
                        value='configuration'>
                        <PiGearLight className='mr-2 size-5' />
                        Configuration
                    </TabsTrigger>

                    <div className='flex w-full justify-end gap-2'>
                        {['accessPoints', 'fte', 'sites', 'switches'].includes(
                            selectedTab,
                        ) && (
                            <>
                                <AddNewDialogContainer
                                    selectedTab={selectedTab}
                                />
                                <Separator
                                    orientation='vertical'
                                    className='mx-2 h-8'
                                />
                            </>
                        )}
                        <Button
                            disabled={!isDirty}
                            variant='outline'
                            size='sm'
                            onClick={resetData}>
                            Reset
                        </Button>
                        <Button
                            disabled={!isDirty}
                            className='relative'
                            size='sm'
                            withIcon
                            onClick={handleSaveModel}>
                            <CiFloppyDisk className='mr-1 size-4 stroke-1' />
                            Save
                            {isDirty && (
                                <span className='absolute -right-1 -top-4 text-3xl text-newDesign-warning'>
                                    •
                                </span>
                            )}
                        </Button>
                    </div>
                </TabsList>

                <TabsContent value='sites'>
                    <SiteTable />
                </TabsContent>

                <TabsContent className='w-full' value='accessPoints'>
                    <AccessPointsTable />
                </TabsContent>

                <TabsContent className='w-full' value='switches'>
                    <SwitchesTable />
                </TabsContent>

                <TabsContent value='fte'>
                    <FteTable />
                </TabsContent>

                <TabsContent value='licenses'>
                    <Licenses />
                </TabsContent>

                <TabsContent value='configuration'>
                    <Configuration />
                </TabsContent>
            </Tabs>
        </div>
    );
};

export default MistSettings;
