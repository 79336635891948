/* eslint-disable react-hooks/rules-of-hooks */
import { ColumnDef } from '@tanstack/react-table';
import React, { useState } from 'react';
import { CiCircleInfo } from 'react-icons/ci';
import { MdEdit } from 'react-icons/md';
import { RiDeleteBin2Line } from 'react-icons/ri';
import { toast } from 'react-toastify';

import TooltipWrapper from '@/components/ui/TooltipWrapper.tsx';
import { Button } from '@/components/ui/button.tsx';
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from '@/components/ui/dialog.tsx';
import { Separator } from '@/components/ui/separator.tsx';
import useMistStore, {
    getIsSiteWithDevice,
} from '@/features/digital-twin/MistSettings/store';
import { cn } from '@/lib/utils.ts';

import type { IQuantityTimeSeriesEntry, SiteInput } from '../../types.ts';
import DelayedDeploymentDialog from '../shared/DelayedDeployment/DelayedDeploymentDialog.tsx';
import StatusBadge from '../shared/StatusBadge.tsx';
import SiteDialog from './components/SiteDialog.tsx';

export const columnConfig: ColumnDef<SiteInput>[] = [
    {
        accessorKey: 'status',
        header: () => <p className='text-newDesign-text-secondary'>Status</p>,
        cell: (ctx) => {
            const status: 'modified' | 'added' | 'original' =
                ctx.row.getValue('status');

            const mapStatus = {
                modified: 'Modified',
                added: 'Added',
                original: 'Original',
            } as const;

            return (
                <p className='flex w-[80px] items-center gap-2'>
                    <StatusBadge status={ctx.row.getValue('status')} />
                    {mapStatus[status]}
                </p>
            );
        },
    },
    {
        accessorKey: 'site',
        header: () => (
            <p className='text-newDesign-text-secondary'>Site Name</p>
        ),
        cell: (ctx) => {
            return (
                <TooltipWrapper
                    align='start'
                    alignOffset={-5}
                    pointerEvents={false}
                    text={ctx.row.getValue('site')}>
                    <p className='max-w-[300px] overflow-hidden text-ellipsis whitespace-nowrap'>
                        {ctx.row.getValue('site')}
                    </p>
                </TooltipWrapper>
            );
        },
    },
    {
        accessorKey: 'currentQty',
        header: () => (
            <TooltipWrapper text='Quantity deployed at the delay 0, which is the present moment.'>
                <span className='text-center text-newDesign-text-secondary'>
                    <p className='whitespace-nowrap'>Current</p>
                    <p className='whitespace-nowrap'>
                        Quantity
                        <CiCircleInfo className='ml-1 inline-block size-3.5 stroke-1 text-newDesign-secondary' />
                    </p>
                </span>
            </TooltipWrapper>
        ),
        cell: (ctx) => {
            const qtyTimeSeries = ctx.row.original.qtyTimeSeries;

            const delayZeroTimeSeries = qtyTimeSeries.find(
                (entry) => entry.delay === 0,
            );

            return delayZeroTimeSeries ? (
                <p className='mr-4 flex items-center justify-center'>
                    {delayZeroTimeSeries.quantity}
                </p>
            ) : (
                <TooltipWrapper text='See the delayed deployments.'>
                    <p className='mr-4 flex items-center justify-center'>
                        <CiCircleInfo className='size-4' />
                    </p>
                </TooltipWrapper>
            );
        },
    },
    {
        id: 'delayedDeployments',
        header: () => (
            <span className='text-center text-newDesign-text-secondary'>
                <p className='whitespace-nowrap'> Delayed </p>
                <p>Deployments</p>
            </span>
        ),
        cell: (ctx) => {
            const updateSite = useMistStore((state) => state.updateSite);

            const site = ctx.row.original;
            const qtyTimeSeries = ctx.row.original.qtyTimeSeries;

            const handleSave = (data: IQuantityTimeSeriesEntry[]) => {
                const updatedSite: SiteInput = {
                    ...site,
                    qtyTimeSeries: data,
                };

                updateSite(site, updatedSite);
            };

            return (
                <div className='group mr-4 flex items-center justify-center rounded-sm p-1'>
                    <DelayedDeploymentDialog
                        onSave={handleSave}
                        data={qtyTimeSeries}
                        disabled={false}
                    />
                </div>
            );
        },
    },
    {
        accessorKey: 'country',
        header: () => (
            <span className='text-center text-newDesign-text-secondary'>
                <p className='whitespace-nowrap'>Country</p>
            </span>
        ),
        cell: (ctx) => {
            return (
                <p className='mr-4 text-center'>
                    {ctx.row.getValue('country')}
                </p>
            );
        },
    },
    {
        accessorKey: 'address',
        header: () => (
            <span className='text-newDesign-text-secondary'>
                <p className='whitespace-nowrap'>Address</p>
            </span>
        ),
        cell: (ctx) => {
            return (
                <p className='max-w-[200px] overflow-hidden text-ellipsis whitespace-nowrap'>
                    {ctx.row.getValue('address')}
                </p>
            );
        },
    },
    {
        accessorKey: 'timeZone',
        header: () => (
            <span className='text-newDesign-text-secondary'>
                <p className='whitespace-nowrap'>Time Zone</p>
            </span>
        ),
        cell: (ctx) => {
            return <p>{ctx.row.getValue('timeZone')}</p>;
        },
    },
    {
        id: 'actions',
        accessorKey: 'actions',
        header: () => (
            <p className='text-center text-newDesign-text-secondary'>Actions</p>
        ),
        cell: (ctx) => {
            const [isEditing, setIsEditing] = useState(false);
            const [isDeleting, setIsDeleting] = useState(false);

            const deleteSite = useMistStore((state) => state.deleteSite);

            const isSiteWithDevice = getIsSiteWithDevice(ctx.row.original.site);

            const handleDeleteAccessPoint = () => {
                if (isSiteWithDevice) {
                    toast.error(
                        'This site has devices making reference to it.',
                    );
                    return setIsDeleting(false);
                }
                deleteSite(ctx.row.original);
            };

            return (
                <div className='mr-4 flex items-center justify-center gap-2'>
                    <Dialog open={isEditing} onOpenChange={setIsEditing}>
                        <DialogTrigger asChild>
                            <Button
                                size='icon'
                                variant='text'
                                className='group size-6 bg-transparent p-1 disabled:cursor-default disabled:bg-opacity-10'>
                                <MdEdit
                                    className={cn(
                                        'size-4 group-hover:text-white',
                                    )}
                                />
                            </Button>
                        </DialogTrigger>
                        <SiteDialog
                            isOpen={isEditing}
                            closeModal={() => setIsEditing(false)}
                            site={ctx.row.original}
                        />
                    </Dialog>

                    <Dialog open={isDeleting} onOpenChange={setIsDeleting}>
                        <DialogTrigger>
                            <Button
                                size='icon'
                                variant='text'
                                className='group size-6 bg-transparent p-1 hover:bg-newDesign-error-light active:bg-newDesign-error disabled:cursor-default disabled:bg-opacity-10'>
                                <RiDeleteBin2Line
                                    className={cn(
                                        'size-4 text-newDesign-error group-hover:text-white',
                                    )}
                                />
                            </Button>
                        </DialogTrigger>
                        <DialogContent className='max-w-sm'>
                            <DialogHeader>
                                <DialogTitle className='flex items-center'>
                                    <RiDeleteBin2Line className='mr-2 size-8 text-newDesign-error-dark' />
                                    Delete Site
                                </DialogTitle>
                                <DialogDescription>
                                    Are you sure you want to delete this Site?
                                </DialogDescription>
                            </DialogHeader>

                            <ul className='list-disc pl-5'>
                                <li className='[word-break:break-word]'>
                                    {ctx.row.original.site}
                                </li>
                            </ul>

                            <Separator orientation='horizontal' />

                            <DialogFooter className='items-center'>
                                <Button onClick={handleDeleteAccessPoint}>
                                    Delete
                                </Button>
                            </DialogFooter>
                        </DialogContent>
                    </Dialog>
                </div>
            );
        },
        size: 120,
        meta: { sticky: 'right' },
    },
];
