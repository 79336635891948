/* eslint-disable react-hooks/rules-of-hooks */
import { ColumnDef } from '@tanstack/react-table';
import React, { useState } from 'react';
import { MdEdit } from 'react-icons/md';
import { RiDeleteBin2Line } from 'react-icons/ri';

import { Currency } from '@bae/store';
import { divide, formatPercent } from '@bae/utils';

import TooltipWrapper from '@/components/ui/TooltipWrapper.tsx';
import { Button } from '@/components/ui/button.tsx';
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from '@/components/ui/dialog.tsx';
import { Separator } from '@/components/ui/separator.tsx';
import useMistStore from '@/features/digital-twin/MistSettings/store';
import { cn } from '@/lib/utils.ts';

import type { FteInput } from '../../types.ts';
import StatusBadge from '../shared/StatusBadge.tsx';
import FteDialog from './components/FteDialog.tsx';

export const columnConfig = (currency: Currency): ColumnDef<FteInput>[] => [
    {
        accessorKey: 'status',
        header: () => <p className='text-newDesign-text-secondary'>Status</p>,
        cell: (ctx) => {
            const status: 'modified' | 'added' | 'original' =
                ctx.row.getValue('status');

            const mapStatus = {
                modified: 'Modified',
                added: 'Added',
                original: 'Original',
            } as const;

            return (
                <p className='flex w-[80px] items-center gap-2'>
                    <StatusBadge status={ctx.row.getValue('status')} />
                    {mapStatus[status]}
                </p>
            );
        },
    },
    {
        accessorKey: 'fteName',
        header: () => <p className='text-newDesign-text-secondary'>FTE</p>,
        cell: (ctx) => {
            return (
                <TooltipWrapper
                    align='start'
                    alignOffset={-5}
                    pointerEvents={false}
                    text={ctx.row.getValue('fteName')}>
                    <p className='max-w-[300px] overflow-hidden text-ellipsis whitespace-nowrap'>
                        {ctx.row.getValue('fteName')}
                    </p>
                </TooltipWrapper>
            );
        },
    },
    {
        accessorKey: 'annualExpense',
        header: () => (
            <span className='text-center text-newDesign-text-secondary'>
                <p>Fully Loaded</p>
                <p>Annual Expense</p>
            </span>
        ),
        cell: (ctx) => {
            return (
                <p className='mr-4 flex items-center justify-center'>
                    {Number(ctx.row.getValue('annualExpense')).toLocaleString(
                        'en-US',
                        {
                            style: 'currency',
                            currency,
                        },
                    )}
                </p>
            );
        },
    },
    {
        accessorKey: 'annualExpenseCagr',
        header: () => (
            <p className='text-center text-newDesign-text-secondary'>
                Wage Growth Rate
            </p>
        ),
        cell: (ctx) => {
            const numberAsPercent = divide(
                Number(ctx.row.getValue('annualExpenseCagr')),
                100,
            );

            return (
                <p className='mr-4 flex items-center justify-center'>
                    {formatPercent(numberAsPercent, 2)}
                </p>
            );
        },
    },
    {
        accessorKey: 'quantity',
        header: () => (
            <span className='text-center text-newDesign-text-secondary'>
                <p className='whitespace-nowrap'>Number of FTEs</p>
            </span>
        ),
        cell: (ctx) => {
            return (
                <p className='mr-4 flex items-center justify-center'>
                    {ctx.row.getValue('quantity')}
                </p>
            );
        },
    },
    {
        id: 'actions',
        accessorKey: 'actions',
        header: () => (
            <p className='text-center text-newDesign-text-secondary'>Actions</p>
        ),
        cell: (ctx) => {
            const [isEditing, setIsEditing] = useState(false);
            const [isDeleting, setIsDeleting] = useState(false);

            const deleteFte = useMistStore((state) => state.deleteFte);

            const handleDeleteAccessPoint = () => {
                deleteFte(ctx.row.original);
                setIsDeleting(false);
            };

            return (
                <div className='mr-4 flex items-center justify-center gap-2'>
                    <Dialog open={isEditing} onOpenChange={setIsEditing}>
                        <DialogTrigger asChild>
                            <Button
                                size='icon'
                                variant='text'
                                className='group size-6 bg-transparent p-1 disabled:cursor-default disabled:bg-opacity-10'>
                                <MdEdit
                                    className={cn(
                                        'size-4 group-hover:text-white',
                                    )}
                                />
                            </Button>
                        </DialogTrigger>
                        <FteDialog
                            isOpen={isEditing}
                            closeModal={() => setIsEditing(false)}
                            fte={ctx.row.original}
                        />
                    </Dialog>

                    <Dialog open={isDeleting} onOpenChange={setIsDeleting}>
                        <DialogTrigger>
                            <Button
                                size='icon'
                                variant='text'
                                className='group size-6 bg-transparent p-1 hover:bg-newDesign-error-light active:bg-newDesign-error disabled:cursor-default disabled:bg-opacity-10'>
                                <RiDeleteBin2Line
                                    className={cn(
                                        'size-4 text-newDesign-error group-hover:text-white',
                                    )}
                                />
                            </Button>
                        </DialogTrigger>
                        <DialogContent className='max-w-sm'>
                            <DialogHeader>
                                <DialogTitle className='flex items-center'>
                                    <RiDeleteBin2Line className='mr-2 size-8 text-newDesign-error-dark' />
                                    Delete FTE
                                </DialogTitle>
                                <DialogDescription>
                                    Are you sure you want to delete this FTE?
                                </DialogDescription>
                            </DialogHeader>

                            <ul className='list-disc pl-5'>
                                <li className='[word-break:break-word]'>
                                    {ctx.row.original.fteName}
                                </li>
                            </ul>

                            <Separator orientation='horizontal' />

                            <DialogFooter className='items-center'>
                                <Button onClick={handleDeleteAccessPoint}>
                                    Delete
                                </Button>
                            </DialogFooter>
                        </DialogContent>
                    </Dialog>
                </div>
            );
        },
        size: 120,
        meta: { sticky: 'right' },
    },
];
