import { z } from 'zod';

import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { GrPowerReset } from 'react-icons/gr';
import { HiOutlineCurrencyDollar, HiOutlineLockClosed } from 'react-icons/hi';
import { LuMail, LuUser } from 'react-icons/lu';
import { PiEyeClosed, PiEye } from 'react-icons/pi';
import { PiGearLight } from 'react-icons/pi';
import { toast } from 'react-toastify';

import { useUser } from '@bae/auth';
import { Currency, useCurrency } from '@bae/store';
import { formatCurrencySymbol } from '@bae/utils';

import { Button } from '@/components/ui/button.tsx';
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '@/components/ui/form.tsx';
import { Input } from '@/components/ui/input.tsx';
import {
    Select,
    SelectTrigger,
    SelectValue,
    SelectContent,
    SelectGroup,
    SelectItem,
} from '@/components/ui/select';
import { Separator } from '@/components/ui/separator.tsx';
import { useEditUserName } from '@/features/user-settings/api/edit-user-name.ts';
import { useEditUserPassword } from '@/features/user-settings/api/edit-user-password.ts';

import EuropeFlag from './flags/eu.svg';
import UKFlag from './flags/gb.svg';
import USAFlag from './flags/us.svg';
import { zodResolver } from '@hookform/resolvers/zod';

const spacesInSequenceRegex = /\s{2,}/g;

// Regex to check for allowed characters in the name
// This regex allows for a first name, any number of middle names, and a last name.
// Each name part must be at least 2 characters long.
// The pattern allows for spaces, commas, periods, apostrophes, and hyphens.
const nameRegex = /^[A-Za-zÀ-ÖØ-öø-ÿ ,.'-]+(?:\s[A-Za-zÀ-ÖØ-öø-ÿ ,.'-]+)*$/;

// Regex to check for inappropriate special characters
const invalidCharRegex = /[@#$%&.,*()=+[\]{}|<>?/!]/;

const userFormSchema = z.object({
    name: z
        .string()
        .min(4, { message: 'Name must be more than 4 characters long.' })
        .max(50, { message: 'Name must be no more than 50 characters long.' })
        .refine(
            (name) => !spacesInSequenceRegex.test(name),
            "Name can't have multiple spaces in a row.",
        )
        .refine(
            (name) => !invalidCharRegex.test(name) && nameRegex.test(name),
            'Name must not include numbers or special characters such as @,!, %, &.',
        ),

    email: z.string().email(),
});

const UserSettingsForm = () => {
    const { user, setUser, isFetchingUserData } = useUser();

    const form = useForm<z.infer<typeof userFormSchema>>({
        resolver: zodResolver(userFormSchema),
        defaultValues: {
            name: user?.fullname || '',
            email: user?.email || '',
        },
    });

    const editUserNameQuery = useEditUserName({
        mutationConfig: {
            onSuccess: () => {
                toast.success('User name updated successfully', {
                    autoClose: 2000,
                });
                setUser({ ...user, fullname: form.getValues('name') });
            },
            onError: () => {
                toast.error('Failed to update user name');
            },
        },
    });

    useEffect(() => {
        if (user?.fullname) form.setValue('name', user.fullname);
        if (user?.email) form.setValue('email', user.email);
    }, [user]);

    const onSubmit = (values: z.infer<typeof userFormSchema>) => {
        editUserNameQuery.mutate({ name: values.name });
    };

    const valuesHaveChanged =
        form.formState.isDirty &&
        (user.fullname !== form.watch('name') ||
            user.email !== form.watch('email'));

    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className='space-y-4'>
                <FormField
                    control={form.control}
                    name='email'
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel className='text-newDesign-text-secondary'>
                                E-mail
                            </FormLabel>
                            <FormControl>
                                <div className='relative'>
                                    <LuMail className='absolute left-2 top-1/2 size-4 -translate-y-1/2 transform text-newDesign-primary' />
                                    <Input
                                        className='pointer-events-none pl-8 text-newDesign-text-secondary'
                                        disabled={false}
                                        placeholder='E-mail...'
                                        {...field}
                                    />
                                </div>
                            </FormControl>
                            <FormMessage className='text-newDesign-error-dark' />
                        </FormItem>
                    )}
                />
                <FormField
                    control={form.control}
                    name='name'
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel className='text-newDesign-text-secondary'>
                                Full Name
                            </FormLabel>
                            <FormControl>
                                <div className='relative'>
                                    <LuUser className='absolute left-2 top-1/2 size-4 -translate-y-1/2 transform text-newDesign-primary' />
                                    <Input
                                        autoComplete={'off'}
                                        className='pl-8'
                                        disabled={
                                            isFetchingUserData ||
                                            editUserNameQuery.isPending
                                        }
                                        placeholder='Full name...'
                                        {...field}
                                    />
                                </div>
                            </FormControl>
                            <FormMessage className='text-newDesign-error-dark' />
                        </FormItem>
                    )}
                />

                <Separator className='w-[calc(100%+3rem)] translate-x-[-1.5rem]' />
                <div className='flex justify-end'>
                    <Button
                        disabled={
                            editUserNameQuery.isPending || !valuesHaveChanged
                        }
                        variant='outline'
                        type='submit'>
                        {editUserNameQuery.isPending && (
                            <GrPowerReset className='mr-2 size-4 animate-spin text-newDesign-primary' />
                        )}
                        Save
                    </Button>
                </div>
            </form>
        </Form>
    );
};

interface PasswordError {
    message: string;
    error: boolean;
}

export const validatePassword = (
    password: string,
    confirmationPassword: string,
): PasswordError[] => {
    return [
        {
            message: 'Password must contain a lower case letter',
            error: !/[a-z]/.test(password),
        },
        {
            message: 'Password must contain an upper case letter',
            error: !/[A-Z]/.test(password),
        },
        {
            message: 'Password must contain a number',
            error: !/[0-9]/.test(password),
        },
        {
            message: 'Password must be at least 8 characters long',
            error: password.length < 8,
        },
        {
            message: 'Password must contain a special character or a space',
            error: !/[^A-Za-z0-9]/.test(password),
        },
        {
            message: 'Password must not contain leading or trailing spaces',
            error: /^\s|\s$/.test(password),
        },
        {
            message: 'Passwords do not match',
            error: password !== confirmationPassword,
        },
    ];
};

const passwordFormSchema = z
    .object({
        previousPassword: z.string().min(1, 'Current password is required.'),
        proposedPassword: z.string().min(1, 'New password is required.'),
        passwordConfirmation: z
            .string()
            .min(1, 'Password confirmation is required.'),
    })
    .superRefine(({ proposedPassword, passwordConfirmation }, ctx) => {
        const errors = validatePassword(proposedPassword, passwordConfirmation);
        errors.forEach(({ error, message }) => {
            if (error) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: message,
                    path: ['passwordConfirmation'],
                });
            }
        });
    });

const PasswordForm = ({ showPassword }: { showPassword: boolean }) => {
    const form = useForm<z.infer<typeof passwordFormSchema>>({
        resolver: zodResolver(passwordFormSchema),
        defaultValues: {
            previousPassword: '',
            proposedPassword: '',
            passwordConfirmation: '',
        },
    });

    const editUserPasswordQuery = useEditUserPassword({
        mutationConfig: {
            onSuccess: () => {
                toast.success('Password updated successfully', {
                    autoClose: 2000,
                });
            },
            onError: () => {
                toast.error('Failed to update password');
            },
        },
    });

    const onSubmit = (values: z.infer<typeof passwordFormSchema>) => {
        editUserPasswordQuery.mutate({
            previousPassword: values.previousPassword,
            proposedPassword: values.proposedPassword,
        });
    };

    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className='space-y-4'>
                <FormField
                    disabled={editUserPasswordQuery.isPending}
                    control={form.control}
                    name='previousPassword'
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel className='text-newDesign-text-secondary'>
                                Current Password
                            </FormLabel>
                            <FormControl>
                                <Input
                                    type={showPassword ? 'text' : 'password'}
                                    placeholder='Enter your current password'
                                    {...field}
                                />
                            </FormControl>
                            <FormMessage className='text-newDesign-error-dark' />
                        </FormItem>
                    )}
                />
                <FormField
                    disabled={editUserPasswordQuery.isPending}
                    control={form.control}
                    name='proposedPassword'
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel className='text-newDesign-text-secondary'>
                                New Password
                            </FormLabel>
                            <FormControl>
                                <Input
                                    type={showPassword ? 'text' : 'password'}
                                    placeholder='Enter your new password'
                                    {...field}
                                />
                            </FormControl>
                            <FormMessage className='text-newDesign-error-dark' />
                        </FormItem>
                    )}
                />
                <FormField
                    disabled={editUserPasswordQuery.isPending}
                    control={form.control}
                    name='passwordConfirmation'
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel className='text-newDesign-text-secondary'>
                                Confirm New Password
                            </FormLabel>
                            <FormControl>
                                <Input
                                    type={showPassword ? 'text' : 'password'}
                                    placeholder='Repeat the password'
                                    {...field}
                                />
                            </FormControl>
                            <FormMessage className='text-newDesign-error-dark' />
                        </FormItem>
                    )}
                />
                <Separator className='w-[calc(100%+3rem)] translate-x-[-1.5rem]' />
                <div className='flex justify-end gap-4'>
                    <Button
                        disabled={editUserPasswordQuery.isPending}
                        variant='outline'
                        type='submit'>
                        {editUserPasswordQuery.isPending && (
                            <GrPowerReset className='mr-2 size-4 animate-spin text-newDesign-primary' />
                        )}
                        Change Password
                    </Button>
                </div>
            </form>
        </Form>
    );
};

const UserSettingsSection = () => {
    const [showPassword, setShowPassword] = useState(false);
    return (
        <section className='flex flex-col gap-4 rounded-lg border border-newDesign-divider p-6 pb-4'>
            <div className='flex items-center'>
                <PiGearLight className='mr-2 size-6 stroke-[5] text-newDesign-primary' />
                <h2 className='text-lg font-semibold'>User Settings</h2>
            </div>

            <UserSettingsForm />

            <div className='flex items-center'>
                <HiOutlineLockClosed className='mr-2 size-6 stroke-[1.5] text-newDesign-primary' />
                <h2 className='text-lg font-semibold'>Password</h2>
                <div className='flex flex-1 items-center justify-end'>
                    <button
                        onClick={() => setShowPassword(!showPassword)}
                        className='flex gap-2 text-newDesign-primary'>
                        {showPassword ? (
                            <>
                                <p className='text-xs'>Hide password</p>{' '}
                                <PiEyeClosed className='size-4' />
                            </>
                        ) : (
                            <>
                                <p className='text-xs'>Show password</p>
                                <PiEye className='size-4' />
                            </>
                        )}
                    </button>
                </div>
            </div>
            <PasswordForm showPassword={showPassword} />
        </section>
    );
};

const AccountSettings = () => {
    const { currency, CURRENCIES, handleSetCurrency, loading } = useCurrency();

    const getCurrencyOptions = () => {
        return Object.entries(CURRENCIES).map(([currency, name]) => ({
            value: currency,
            label: `${formatCurrencySymbol({
                currency,
                onlySymbol: true,
            })} - ${name}`,
            flag: getCountryFlag(currency) || null,
        }));
    };

    return (
        <div className='flex w-full flex-1 flex-col gap-4'>
            <h1 className='text-2xl font-bold text-newDesign-black'>
                Account Settings
            </h1>
            <Separator />
            <div className='mx-auto flex w-[600px] flex-col gap-4'>
                <section className='h-[160px] rounded-lg border border-newDesign-divider p-6'>
                    <div className='flex items-center'>
                        <HiOutlineCurrencyDollar className='mr-2 size-6 stroke-[1.5] text-newDesign-primary' />
                        <h2 className='text-lg font-semibold'>
                            Account Currency
                        </h2>
                    </div>
                    <p className='mt-4 font-light'>
                        Choose your preferred currency
                    </p>

                    <Select
                        disabled={loading}
                        onValueChange={(value) =>
                            handleSetCurrency(value as Currency)
                        }
                        value={currency}>
                        <SelectTrigger className='mt-2'>
                            <SelectValue placeholder='Select currency' />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectGroup>
                                {getCurrencyOptions().map((option) => (
                                    <SelectItem
                                        key={option.value}
                                        value={option.value}>
                                        <div className='flex items-center gap-2'>
                                            <span className='flex size-6 items-center justify-center overflow-hidden rounded-full'>
                                                {option.flag}
                                            </span>
                                            <span>{option.label}</span>
                                        </div>
                                    </SelectItem>
                                ))}
                            </SelectGroup>
                        </SelectContent>
                    </Select>
                </section>
                <UserSettingsSection />
            </div>
        </div>
    );
};

const getCountryFlag = (code: string) => {
    switch (code) {
        case 'EUR':
            return <EuropeFlag />;
        case 'GBP':
            return <UKFlag />;
        case 'USD':
            return <USAFlag />;
        default:
            return null;
    }
};

export default AccountSettings;
